function encriptionHelper() {
    let self = {};
  
    self.encodeAndEncryptURLParams = (params) => {
        console.log({params})
        const encodedParams = Object.keys(params)
          .map((key) => {
            const encodedKey = btoa(encodeURIComponent(key));
            const encodedValue = btoa(encodeURIComponent(JSON.stringify(params[key])));
            return encodedKey + '~~' + encodedValue; // Replace '=' with '~~' to avoid decryption issues
          })
          .join('&');
      
        const encryptedParams = encodeURIComponent(btoa(encodedParams));
        return encryptedParams;
    }

    self.decryptURLParams = (encryptedParams) => {
      console.log(encryptedParams);
        const rawParams = atob(decodeURIComponent(encryptedParams));
        const params = rawParams.split('&').reduce((prev, curr) => {
          let obj = curr.split('~~'); // Split on '~~' instead of '='
          prev[decodeURIComponent(atob(decodeURIComponent(obj[0])))] = JSON.parse(decodeURIComponent(atob(decodeURIComponent(obj[1]))));
          return prev;
        }, {});
      
        return params;
    }

    return self;
}

export default encriptionHelper;