
function transformationHelper() {
    let self = {};
  
    self.getTransformationFunction = (data) => {
        if(data.videoPlaylist != undefined) {
            return {
                ...data,
                "id": "p_" + data?.id,
                "pid": data?.id,
                "name": data?.name,
                "date": data?.updated_at,
                'created_at': data?.created_at,
                'numVideos': data?.videoPlaylist?.length,
                'image': data?.thumbNailImage,
            }
        } else  if(data.audioUrl != undefined) {
            return {
                ...data,
                "id": "a_" + data?.id,
                "aid": data?.id,
            }
        } else {
            return {
                ...data,
                "id": "v_" + data?.id,
                "vid": data?.id,
            }
        }
       
    }

    return self;
}

export default transformationHelper;