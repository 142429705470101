import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Stack, Card, Fab, CardContent, CardMedia, CardActionArea, Grid, TextField, Chip, InputLabel, MenuItem, Avatar, Link, FormControl, Select, Typography, Autocomplete, Button, Divider, Snackbar, Alert } from '@mui/material';
import ApiService from '../../../../../../utils/ApiService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { v4 as uuidv4 } from 'uuid';
import { getAppToken, getSelfToken } from '../../../../../../_helper/secureToken';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer, closeDrawer } from '../../../../../../features/drawers/drawerSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import '../../../../../../layouts/footer/Footer.css'
import CreatePlaylistDialog from '../../../../../../pages/KnowledgeManagement/DialogKM/CreatePlaylistDialog';
import SharedDialog from '../../../../../../features/dialogBox/dialogBox';
import { openSnackbar, closeSnackbar } from '../../../../../../features/snackbar/snackbarSlice';
import { openDialog, closeDialog } from "../../../../../../features/dialogBox/dialogBoxSlice";
// import MyContext from '../../../../../../pages/KnowledgeManagement/Mycontext';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { setRecentTags } from '../../../../../../features/dashboardData/recentTagsData';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Details = ({ context, goToAdvance, detailsData, setDetailsData }) => {
  console.log('a23',detailsData)
  const methodsAndState = useContext(context);
  const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
  console.log(selectedData)
  const [uploadMode, setUploadMode] = useState(methodsAndState.uploadMode ?? 'video');
  console.log('a4',uploadMode)
  const allowedTypes = uploadMode == 'video' ? ['video/mp4', 'video/avi', 'video/quicktime', 'video/x-matroska', 'video/x-ms-wmv'] : ['audio/mpeg', 'audio/wav', 'audio/mp3', 'audio/x-m4a'];

  // useEffect(() => {
  //   setUploadMode(methodsAndState.uploadMode);
  //   console.log("hozu hozu re", uploadMode, methodsAndState.uploadMode);
  // }, [methodsAndState.uploadMode]);

  // const chunkSize = 1048576 * 1;//its 1MB, increase the number measure in mb
  const chunkSize = 1048576 * 0.5;
  const [showProgress, setShowProgress] = useState(false)
  const [counter, setCounter] = useState(1)
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const [fileSize, setFileSize] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  console.log("a23", {selectedValues: detailsData?.selectedValues})
  const [categoriesName, setCategoriesName] = useState(detailsData?.selectedValues?.reduce((prev, curr) => {

    if (curr?.subcategoryId) {
      console.log('srbgrt', {curr});
      prev = [...prev, ...curr.subcategoryId];
    }
    return prev;
  }, [])?.map(v => { return { id: v } }) ?? []);
  // const [categoriesName, setCategoriesName] = useState([]);
  // const [currentPlaylist, setCurrentPlaylist] = useState(0);
  const [fileName, setFileName] = useState("")
  const [validCategory, setValidCategory] = useState([])
  const [chunkCount, setChunkCount] = useState(0)
  const [videoStream, setVideoStream] = useState(detailsData.videoStream ?? "")
  const [video, setVideo] = useState("")
  const [videoDetail, setVideoDetail] = useState('');
  const [ categoryList, setCategoryList] = useState(true);
  const userId = useSelector((state) => state?.user?.userListName.userId)
  console.log('abc', userId)
  const userDetail = useSelector((state) => state?.user?.userListName)
  console.log('abc', userDetail)
  // const userList = useSelector((state) => state?.user?.userListName);
  const isRecommanded = window.location.pathname == "/recommended";

  useEffect(() => {
    const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
    console.log("3", { selectedData });

    if (isRecommanded) {
      setUploadMode(selectedData?.audioUrl ? 'audio' : 'video')
    }
  }, [])

  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
  }

  const [isChecked, setChecked] = useState(detailsData.isChecked ?? false);
  console.log('a23', isChecked,detailsData);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    console.log('a23',event.target.checked)
  };
  // const progressInstance = <ProgressBar animated now={progress} label={`${progress.toFixed(3)}%`} />;
  const progressInstance = <Box>{progress}</Box>;
  const [playlistData, setPlaylistData] = React.useState([]);
  const [playlist, setPlaylist] = React.useState(detailsData.playlist ?? []);
  const [categories, setCategories] = useState(detailsData.categories ?? []);
  const [selectedValues, setSelectedValues] = useState(detailsData.selectedValues ?? []);
  const [draftVideoID, setDraftVideoID] = useState(detailsData.draftVideoID ?? '');
  const [url, setURL] = useState(detailsData.url ?? '');
  console.log('a10',url)
  const [uploadVideoDetails, setUploadVideoDetails] = useState({});
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const handleChangePlaylist = (event, values) => {
    setPlaylist(values);
  };

  console.log("Bloo", { categoriesName, selectedValues })

  const [categoryData, setCategoryData] = React.useState(detailsData.categoryData ?? []);
  const [Category, setCategory] = React.useState(detailsData.Category ?? '');

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const [subcategoryData, setSubcategoryData] = React.useState(detailsData.subcategoryData ?? []);
  const [Subcategory, setSubcategory] = React.useState(detailsData.Subcategory ?? '');

  const handleChangeSubcategory = (event) => {
    setSubcategory(event.target.value);
  };

  const [tags, setTags] = useState(detailsData.tags ?? []);
  console.log('tag', tags)
  const [selectedTags, setSelectedTags] = useState(detailsData.selectedTags ?? []);
  console.log(selectedTags)
  // const [streamTagsName, setStreamTagsName] = React.useState('');

  // const [titleValue, setTitleValue] = useState('');

  // const handleTitleValue = (event) => {
  //   setTitleValue(event.target.value);
  //   console.log(event.target.value)
  // };
  const [titleValue, setTitleValue] = useState(detailsData.titleValue ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorsMessage, setErrorsMessage] = useState('');
  const [existingName, setExistingName] = useState(detailsData.existingName ?? '');

  const handleTitleValue = (event) => {
    const newValue = event.target.value;
    console.log("new", newValue)
    if (newValue.length <= 100) {
      setTitleValue(newValue);

      setErrorMessage('');
    } else {
      setErrorMessage('Error: Title should not exceed 100 characters.');
    }
  };
  const isTitleDifferent = () => {
    return titleValue !== existingName;
  };

  useEffect(() => {
    // Assuming you fetch existing data and set it in `existingData` state
    const existingData = { name: existingName }; // Replace with your actual implementation
    setExistingName(existingData.name);
  }, []);

  const [descriptionValue, setDescriptionValue] = useState(detailsData.descriptionValue ?? '');

  const handleDescriptionValue = (event) => {
    setDescriptionValue(event.target.value);
    console.log(event.target.value)
  };

  const CustomListbox = ({ children, ...other }) => {
    return (
      <List {...other} sx={{ height: '210px !important', overflow: 'hidden !important' }}>
        <Box sx={{ height: '150px', overflow: 'auto' }}>  {children}</Box>
        <Divider />
        <footer >
          <ListItem sx={{ height: '50px !important' }}>
            <Button variant="text" onClick={() => { openDialogPublish('createPlaylistDialog') }}>
              <AddIcon />
              NEW PLAYLIST
            </Button>
          </ListItem>
        </footer>
      </List>
    );
  };

  const [Practice, setPractice] = React.useState('');

  const handleChangePractice = (event) => {
    setPractice(event.target.value);
  };
  const [Industry, setIndustry] = React.useState('');

  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const [Technology, setTechnology] = React.useState('');

  const handleChangeTechnology = (event) => {
    setTechnology(event.target.value);
  };
  const [Location, setLocation] = React.useState('');

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };
  const [Stage, setStage] = React.useState('');

  const handleChangeStage = (event) => {
    setStage(event.target.value);
  };

  // const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(detailsData.selectedFile ?? null);
  const [imageThumb, setImageThumb] = useState(detailsData.imageThumb ?? null);
  console.log('a3')
  const handleFileChange = (e) => {
    // const fileThumbnail = e.target.files[0];
    const file = e.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      setImageThumb(file);
      setSelectedFile(url);
    }
    // You may want to perform additional validations here
    // if (fileThumbnail) {
    //   try {
    //     const objectURL = URL.createObjectURL(fileThumbnail);
    //     // Directly use the file or objectURL as needed
    //     setSelectedFile(objectURL);
    //     console.log(objectURL);
    //   } catch (error) {
    //     console.error('Error creating object URL:', error);
    //   }
    // }

    // Update the state if needed
    // setSelectedFile(file);
    console.log(selectedFile)
    // console.log(fileThumbnail)
  };

  // const handleRemoveImage = () => {
  //   // setFile();
  //   setSelectedFile(null);
  // };


  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
  }, [fileToBeUpload, progress])

  useEffect(() => {
    console.log({ videoStream })
    if (videoStream != "") {
      setTimeout(() => {
        getFileContext();
      }, 100)
    }
  }, [videoStream])

  const updatePlaylistData = async () => {
    try {
      const response = await ApiService.get('allPlaylistName');
      setPlaylistData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // ApiService.get('allPlaylistName').then(playlistData => {
    //   setPlaylistData(playlistData.data.data)
    //       //  console.log(department.data.data)
    //     })
  }
  useEffect(() => {
    // Fetch the initial playlist data when the component mounts
    updatePlaylistData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiService.get('categoryToSubcategory');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if(categories.length){
      setCategoryList(false);
      console.log("Are you sure??", { selectedItems: methodsAndState.selectedItems, dashboardList: methodsAndState.dashboardList, editFlag: methodsAndState.editFlag })
      if (methodsAndState.selectedItems.size == 1 && methodsAndState.editFlag?.value != "create") {
        const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
        console.log("a1", selectedData)
        const uploadModeCalc = isRecommanded ? (selectedData?.audioUrl ? 'audio' : 'video') : uploadMode;
        const params = uploadModeCalc == 'video' ? {
          related: ['streamCategory', 'streamSubcategory']
        } : {};
        let paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
        console.log("Saurabh", { paramsid })
        let api = uploadModeCalc == 'video' ? 'particularPlaylist' : 'particularAudioPlaylist';
        ApiService.get(api, params, paramsid).then(res => {
          if (res.data.data) {
            let data = res.data.data;
            console.log("naam", { data });
            console.log('naam', data?.StreamSubCategory?.streamCategory?.name)
            setDescriptionValue(data.description != null ? data.description : "");
            setExistingName(data.name)
            setTitleValue(data.name);
            setSelectedTags(data.tags)
            setPlaylist(data.playlist)
            setCategory(data?.streamCategory?.id)
            setSubcategory(data?.StreamSubCategoryId)
            setChecked(data.recommendedVideo === "1")
            console.log('a2',data.recommendedVideo)
            setChecked(data.recommendedAudio === "1")
            setSelectedFile(data?.thumbNailUrl || null)
            setVideo(data.videoUrl)
            setCategoriesName(data.subCategory)
            // setSelectedValues(data.subCategory)
            setDraftVideoID(paramsid);
            // console.log('naam', data.recommendedVideo)
            console.log('naam', data.videoUrl)
            console.log("srbgrt", {data, categories: categories});

            let slctdt = data.subCategory.reduce((prev, curr) => {
              // console.log("srbgrt", {curr});
              // prev = [...prev, curr]
              const updatedValues = [...prev];
              let category = categories.reduce((p,c) => {
                console.log('srbgrt', {subCategory: c.subCategory})
                if(c.subCategory.filter(v => v.id == curr.id).length)
                  p = c.name;
                return p;
              }, null)
              const existingValue = updatedValues.find((item) => item.category === category);
              console.log('srbgrt', {existingValue, category})
              if (existingValue) {
                existingValue.subcategoryId = [...existingValue.subcategoryId, curr.id];
              } else {
                updatedValues.push({
                  category,
                  subcategoryId: [curr.id],
                });
              }

              prev = updatedValues;
              return prev;
            }, []);

            setSelectedValues(slctdt);
            setCategoryList(true);
            console.log('srbgrt', {subCategory: slctdt})


            // setSelectedTags(data.tags.map((temp) => {
            //   return temp.name;
            // }));
  
          }
        })
      }
    }
  }, [categories])

  useEffect(() => {

    // updatePlaylistData();

    
    if (!categories.length) {
      fetchData();
    }

    if (!categoryData.length) {
      ApiService.get('allCategoriesName').then(categoryData => {
        setCategoryData(categoryData.data.data)
        //  console.log(department.data.data)
      })
    }

    if (!subcategoryData.length) {
      ApiService.get('allSubcategoriesName').then(subcategoryData => {
        setSubcategoryData(subcategoryData.data.data)
        //  console.log(department.data.data)
      })
    }

    if (!tags.length) {
      ApiService.get('streamTags').then(tags => {
        setTags(tags.data.data)
        //  console.log(department.data.data)
      })
    }


    

    // ApiService.get('categoryToSubcategory').then(categories => {
    //   setCategories(categories.data.data);
    //       //  console.log(department.data.data)
    //     })

    return (() => {
      dispatch(closeDialog('createPlaylistDialog'));
      setDraftVideoID('');
    })
  }, [])

  const handleSelectChange = (category, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: value,
    }));
  };

  // const handleAutocompleteChange = (values, category) => {
  //   const updatedValues = [...selectedValues];
  //   const existingValue = updatedValues.find((item) => item.category === category);

  //   if (existingValue) {
  //     existingValue.subcategoryId = values.map((value) => value.id);
  //   } else {
  //     updatedValues.push({
  //       category,
  //       subcategoryId: values.map((value) => value.id),
  //     });
  //   }

  //   setSelectedValues(updatedValues);
  // };

  const handleAutocompleteChange = (values, category) => {
    const updatedValues = [...selectedValues];
    console.log('srbgrt',{updatedValues, category})
    const existingValue = updatedValues.find((item) => item.category === category);
    console.log('srbgrt', {existingValue})
    if (existingValue) {
      existingValue.subcategoryId = values.map((value) => value.id);
    } else {
      updatedValues.push({
        category,
        subcategoryId: values.map((value) => value.id),
      });
    }

    setSelectedValues(updatedValues);
  };


  const onChangeSelectCategory = (e, value, reason, data, i) => {
    // If the reason for the change is 'removeOption', handle the removal.
    if (reason === "removeOption" && value) {
      let newCategoryName = categoriesName
      const prevValue = newCategoryName.filter(v => v.category[0].id == data.id)
      const currValue = value.map(v => v.id);
      console.log('a11', value.map(v => v.id))
      const lostValue = prevValue.filter(v => currValue.indexOf(v.id) == -1)[0].id;
      const index = newCategoryName.reduce((prev, curr, index) => {
        if (curr.id == lostValue)
          prev = index;
        return prev;
      }, -1);
      if (index != -1)
        newCategoryName.splice(index, 1);
      console.log('a11', newCategoryName.splice(index, 1))
      setCategoriesName([...newCategoryName]); 
      console.log('a11',setCategoriesName([...newCategoryName]))
    } else if (reason === "clear") {
      let newCategoryName = categoriesName
      const currValue = newCategoryName.filter(v => v.category[0].id != data.id)
      console.log('a11',setCategoriesName([...newCategoryName]))
      setCategoriesName([...currValue]);
    } else {
      // Handle the addition of new values (similar to your existing code).
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value.length) {
        const uniqueValues = new Set([...categoriesName, ...value]);
        setCategoriesName(Array.from(uniqueValues));
      }
    }
    let validation = [...validCategory, { index: i, value: value }]
    const uniqueValuesMap = new Map();
    const updatedData = [];

    validation.forEach(item => {
      const index = item.index;
      if (!uniqueValuesMap.has(index)) {
        uniqueValuesMap.set(index, updatedData.length);
        updatedData.push(item);
      } else {
        const existingIndex = uniqueValuesMap.get(index);
        const existingValues = updatedData[existingIndex].value.map(val => val.id);

        item.value.forEach(val => {
          if (!existingValues.includes(val.id)) {
            updatedData[existingIndex].value.push(val);
            existingValues.push(val.id);
          }
        });
      }
    });

    // Update the validCategory array with the filtered data
    setValidCategory(updatedData);

  }

  const isOptionEqualToValue = (value, option) => {
    return value.name === option.name;
    console.log('a11',isOptionEqualToValue,value,option)
  };


  const [errorsMessages, setErrorsMessages] = useState("");
  const [tagCharacterLimitErr, setTagCharacterLimitErr] = useState(false);
  const [characterLimitErr, setCharacterLimitErr] = useState("");

  const handleTagChange = (event, value) => {
    const characterLimit = 10;
  
    const filteredValue = value.filter(tag => {
      if (typeof tag === 'string') {
        return /^[a-zA-Z@_\-\s]*$/.test(tag.trim()) && tag.length <= characterLimit;
      } else {
        return /^[a-zA-Z@_\-\s]*$/.test(tag?.name.trim()) && tag.name.length <= characterLimit;
      }
    });
  
    const invalidTags = value.filter(tag => !filteredValue.includes(tag));
  
    if (invalidTags.length > 0) {
      const errorMessage = "Tags must only contain letters, '@', '_', '-', or spaces.";
      setErrorsMessages(errorMessage);
      setCharacterLimitErr(false);
    } else if (filteredValue.length !== value.length) {
      // Only some tags were removed, not all invalid tags
      setErrorsMessages("");
      setCharacterLimitErr(true);
    } else {
      // No invalid tags
      setErrorsMessages("");
      setCharacterLimitErr(false);
    }
  
    setSelectedTags(filteredValue);
  };
  


  const getFileContext = (e) => {
    resetChunkProperties();
    // const _file = e.target.files[0];
    const _file = videoStream
    setFileSize(_file.size)
    console.debug(fileSize, "chunk")

    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount)


    setFileToBeUpload(_file)
    const _fileID = uuidv4() + "." + _file.name.split('.').pop();
    setFileGuid(_fileID)
    setFileName(_file.name)
  }

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      const chunkData = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      const chunkBlob = new Blob([chunkData], { type: fileToBeUpload.type }); // Create Blob with correct type
      const chunk = new File([chunkBlob], fileToBeUpload.name, { type: fileToBeUpload.type }); // Create File from Blob
      if (counter == chunkCount)
        uploadChunk(chunk, true)
      else
        uploadChunk(chunk)
    }
  }
  const [loading, setLoading] = useState('');
  
  const uploadChunk = async (chunk, is_last = null) => {
    var formData = new FormData();
    console.log({ chunk, size: (0 + chunk.size) })
    if (is_last) {
      formData.append('is_last', true);
      formData.append('file', chunk);
      formData.append('uploadedById', userId);
      formData.append('fileName', fileName);
      formData.append('name', fileName.split(".")[0]);
    } else {
      formData.append('fileName', fileName);
      formData.append('name', fileName.split(".")[0]);
      formData.append('file', chunk);
      formData.append('chunk', true);
    }

    setLoading(true);

    let api = uploadMode == 'video' ? 'particularPlaylist' : 'particularAudioPlaylist';
    ApiService.postAdopter(api, formData).then(response => {

      const data = response.data;
      setVideoDetail(response.data.video)
      setURL(response.data.url)

      if (data.isSuccess) {
        setLoading(false);
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        // props.setVideoURL(data.url.url)
        if (counter == chunkCount) {
          console.debug('Process is complete, counter', counter)
          // await uploadCompleted();
          setProgress(100);
          // props.setVideoURL(data.url.url)
        } else {
          console.debug('Process, counter')
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
        console.log("Saurabh", { is_last, data })
        if (is_last) {
          console.log("Saurabh", { draftId: data?.video?.id ?? data?.audio?.id ?? "" });
          setDraftVideoID(data?.video?.id ?? data?.audio?.id ?? "");
        }
        console.log("Saurabh", { is_last, data })
        // setLoading(false);
      } else {
        console.debug("error else", data);
        console.debug('Error Occurred:', data.errorMessage)
        // setLoading(false);
      }
    }).catch((error) => {
      // methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
      // setFlag(false)
      // setLoading(false);
    }).finally(() => {
      setLoading(false);
    });

  }

  const resetChunkProperties = () => {
    setShowProgress(true)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  const [alertsMessages, setAlertsMessages] = useState('');

  // const onChangeTextHandler = (e) => {
  //   const file = e.target.files[0];
  //   if (file && allowedTypes.includes(file.type)) {
  //     setVideoStream(e.target.files[0]);
  //   } else {
  //     setAlertsMessages(uploadMode === 'video' ? 'Please select a valid video file.' : 'Please select a valid audio file.');
  //     e.target.value = '';
  //   }
  // };
const onChangeTextHandler = (e) => {
  const file = e.target.files[0];
  console.log("a10", file);
  // Check if there is already a file being uploaded
  if (videoStream) {
    setAlertsMessages(uploadMode === 'video' ? 'Please wait until the current video upload is complete.' : 'Please wait until the current audio upload is complete.');
    e.target.value = ''; // Clear the file input
    return; // Stop further execution
  }

  if (file && allowedTypes.includes(file.type)) {
    setVideoStream(e.target.files[0]);
  } else {
    setAlertsMessages(uploadMode === 'video' ? 'Please select a valid video file.' : 'Please select a valid audio file.');
    e.target.value = '';
  }
};

const [editedVideo, setEditedVideo] = useState(null);
const onEditFileChangeHandler = (e) => {
  const file = e.target.files[0];

  console.log("Selected file:", file);

  // Check if the selected file is valid
  if (file && allowedTypes.includes(file.type)) {
    console.log("Valid file selected. Setting edited video...");
    // Set the edited video
    setEditedVideo(file);
  } else {
    // Display an error message if the file is invalid
    setAlertsMessages('Please select a valid video file.');
    e.target.value = '';
  }
};





  const [isUploading, setIsUploading] = useState(false);
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("I am Here");
  };

  const [alertMessages, setAlertMessages] = useState('');
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   if (!isUploading) {

  //     event.stopPropagation();
  //     const files = event.dataTransfer.files;
  //     // Validate files and call selectFiles function
  //     setVideoStream(files[0]);
  //     console.log("I am Here", { files });
  //     setIsUploading(true);
  //   } else {
  //     setAlertMessages(uploadMode === 'video' ? 'Please wait until the current video upload is complete..' : 'Please wait until the current audio upload is complete.');
  //     // alert('Please wait until the current video upload is complete.');
  //   }
  // };
  const handleDrop = (event) => {
    event.preventDefault();
    if (!isUploading) {
      event.stopPropagation();
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        if (allowedTypes.includes(file.type)) {
          setVideoStream(file);
          setIsUploading(true);
          setAlertsMessages('');
        } else {
          setAlertsMessages(uploadMode === 'video' ? 'Please select a valid video file.' : 'Please select a valid audio file.');
        }
      }
    } else {
      setAlertMessages(uploadMode === 'video' ? 'Please wait until the current video upload is complete.' : 'Please wait until the current audio upload is complete.');
    }
  };
  

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  // const recentTags = useSelector((state) => state.recentTag?.recentTags);

  const handleUploadVideoDetails = async (needToGo) => {

    const formData = new FormData();
    console.log("a3", {data:formData})

    // formData.append('fileName', fileName.split(".")[0]);
    if (isTitleDifferent()) {
      formData.append('name', titleValue);
    }
    if (descriptionValue) {
      formData.append('description', descriptionValue);
    }
    // formData.append('uploadedById', userId);
    formData.append('modifiedBy', userId);

    if (Category) {
      formData.append('streamCategoryId', Category);
    }
    formData.append('recommendedAudio', isChecked ? 1 : 0);
    console.log('a2',isChecked)
    formData.append('recommendedVideo', isChecked ? 1 : 0);
    console.log('a2',isChecked)
    formData.append('draft', 0);
    // formData.append('thumbNailImage', selectedFile);
    // if (imageThumb) {
    //   formData.append('thumbNailImage', imageThumb, imageThumb.name);
    // }
    if (imageThumb && (imageThumb !== selectedFile || !selectedFile)) {
      formData.append('thumbNailImage', imageThumb, imageThumb.name);
    }
    setSelectedFile(null);
    setImageThumb(null);
    if (Subcategory) {
      formData.append('streamSubcategoryId', Subcategory);
    }
    playlist.forEach((playlistItem, index) => {
      formData.append(`playlistId[${index}]`, playlistItem.id);
    });
    // thumbNailImage
    selectedTags.forEach((tag, index) => {
      formData.append(`tag[${index}]`, tag.name ?? tag);
    });
    let i = 0;
    selectedValues.forEach(({ subcategoryId }, index) => {
      subcategoryId?.forEach((id, subIndex) => {
        console.log({ name: `subCategoryId[${i}]`, id })
        formData.append(`subCategoryId[${i}]`, id);
        i++;
      });
    });

    try {
      let api = uploadMode == 'video' ? 'particularPlaylistUpdate' : 'particularAudioPlaylistUpdate';
      console.log("Saurabh says its working", { draftVideoID });
      const response = await ApiService.postAdopter(api, formData, draftVideoID);
      console.log("a3", {data:formData})
      setUploadVideoDetails(response);
      console.log("srb", { formData })
      //   const dataParams = {
      //     related: ['streamCategory','streamSubcategory','modifyBy','uploadedBy']
      // }
      ApiService.get('recentTags').then((res) => {
        let data = res.data.data;
        if (data.length) {
          dispatch(setRecentTags(data))
        } else {
          dispatch(setRecentTags(['nodata']))
        }
      });
      const dataParams = uploadMode == 'video' ? {
        related: ['streamCategory', 'streamSubcategory', 'modifyBy', 'uploadedBy']
      } : {
        related: ['audioUploadedBy', 'audioModifyBy']
      };
      let apiGet = uploadMode == 'video' ? 'particularPlaylist' : 'particularAudioPlaylist';
      ApiService.get(apiGet, dataParams, draftVideoID)
        .then(res => {
          let data = res.data.data;

          if (data) {
            if (methodsAndState.editFlag?.value === "create") {
              methodsAndState.addNewVideo(data, isChecked);
              const successMessage = uploadMode === 'video' ? 'Your video has been added successfully' : 'Your audio has been added successfully';

              dispatch(openSnackbar({ dataLoad: true, message: successMessage, severity: "info" }));
            } else {
              let updateData = { ...data }
              updateData['modifyBy'] = { id: userDetail?.userId, firstName: userDetail?.userName, lastName: userDetail?.lastName };
              methodsAndState.editNewVideo(updateData, isChecked);
              const successMessage = uploadMode === 'video' ? 'Your video has been Edited successfully' : 'Your audio has been Edited successfully';
              dispatch(openSnackbar({ dataLoad: true, message: successMessage, severity: "info" }));
            }
            if (needToGo) {
              goToAdvance();
            } else {
              dispatch(closeDrawer());
            }
            ApiService.get('recentTags').then((res) => {
              let data = res.data.data;
              console.log('API Response:', data);
            }).catch((e) => {
              // Handle error
            });
          }
        })
        .catch((e) => {
          // Handle error
        });

      if (isRecommanded) {
        window.location.reload();
      }
      // window.location.reload(true);

    } catch (error) {
      // Handle API call error
      console.log("discussion", { message: error.message, error, originalError: error.originalError })
      if (error.message == 'The video name already exists.') {
        setErrorMessage(error.message);
      } else {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      }
      // alert(error.message)
    }
  };

  // const updatePlaylistCount = (newPlaylist) => {
  //   let allPlaylist = [...playlist];
  //   allPlaylist[currentPlaylist].name = allPlaylist[currentPlaylist].name + 1;
  //   setPlaylist(allPlaylist);
  //   setPlaylistData((prevData) => [...prevData, newPlaylist]);
  // };

  // const updatePlaylistData = (newPlaylist) => {
  //   // Update the playlist data in YourComponent
  //   setPlaylistData((prevData) => [...prevData, newPlaylist]);
  // };

  const filteredValidData = validCategory.map(item => {
    item.value = item.value.filter(subItem => {
      return categoriesName.some(categoryItem => categoryItem.name === subItem.name);
    });
    return item;
  });

  // Check if every filteredValidData has a 'value' property with a length greater than 0
  const hasValueLength = filteredValidData.every(item => item.value.length > 0);


  const disabledFields = methodsAndState.editFlag?.flag || methodsAndState.editFlag?.value == "create";

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    console.log("a23",isChecked,titleValue)
    setDetailsData({ videoStream: videoStream, selectedFile: selectedFile, imageThumb: imageThumb, isChecked: isChecked, titleValue: titleValue, descriptionValue: descriptionValue, playlist: playlist, Category: Category, Subcategory: Subcategory, selectedTags: selectedTags, selectedValues: selectedValues, draftVideoID: draftVideoID, video: video, url: url, categoryData: categoryData, subcategoryData: subcategoryData, existingName: existingName, categories: categories, tags: tags });
  }, [videoStream, selectedFile, imageThumb, isChecked, titleValue, descriptionValue, playlist, Category, Subcategory, selectedTags, selectedValues, draftVideoID, video, url, categoryData, subcategoryData, existingName, categories, tags]);


  // const classes = useStyles();
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      setAudioSrc(reader.result);
      setThumbnail(URL.createObjectURL(file));
    };

    reader.readAsDataURL(file);
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // disableSubmitButton

    let checkedData = selectedValues.length == 4 &&  selectedValues.reduce((prev, curr) => {
      if(!curr.subcategoryId.length){
        prev = false;
      }
      return prev;
    }, true)
    console.log("dekhte hain", {selectedFile})
    setDisableSubmitButton( !checkedData || titleValue.length == '' ||
      descriptionValue.length == '' || (uploadMode != 'audio' && (Category.length == '' || Subcategory.length == '')) || selectedTags.length == '' ||
      loading ||
      (selectedFile == null || !selectedFile && !fileName) && !video)
      // console.log('a10', setDisableSubmitButton())
  }, [titleValue, descriptionValue, selectedFile, fileName, video, loading, Category, Subcategory, selectedTags, selectedValues])

  return (
    // <MyContext.Provider value={methodsAndState}>
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>

            {methodsAndState.editFlag?.value == "create" &&
              <>
                {!url ?
                  <>
                  <Box>
                  </Box>
                         {alertMessages &&
                         <Alert variant="none" sx={{ color: "red" }} severity="error">
                         {alertMessages}
                       </Alert>
                         }
                    <Box>
                        {alertsMessages && (
                        <Alert variant="none" sx={{ color: "red" }} severity="error">
                          {alertsMessages}
                        </Alert>
                      )}
                      </Box>
                    <label htmlFor="video">
                      <Stack
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // marginTop: "0.5rem",
                          border: "2px dashed rgba(0, 0, 0, 0.12)",
                          borderRadius: "12px",
                          height: '220px',
                          // width:'100% !important',
                          backgroundColor: "rgba(201, 201, 201, 0.1)",
                          ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                        }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <Box className="upload-file-icon" sx={{ p: "1rem 0rem", display: loading ? 'none' : 'block', marginTop: '1rem' }}>
                          <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                            <UploadFileIcon color="primary" />
                          </Avatar>
                        </Box>
                        <Box className="upload-btn-wrapper" >
                          <Box style={{ display: loading ? 'none' : 'block', marginTop: '1rem' }}>
                            <span>
                              <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                                Click to upload
                              </Link>
                              <input type="file" className="form-control box-file" id="video"
                                label="Example file input" name="video" onChange={(e) => onChangeTextHandler(e)} accept={allowedTypes} />
                            </span>
                            <span> or drag and drop *</span>
                          </Box>
                          <Box style={{ display: loading ? 'block' : 'none', marginTop: '1rem' }}>
                            <CircularProgress />
                          </Box>
                        </Box>
                      </Stack>
                    </label>
                    <Box style={{ display: progress > 0 && progress < 100 ? 'block' : 'none', marginTop: '1rem' }}>
                      <LinearProgress value={progress} variant="determinate" sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                    </Box>
                    <Box>
                    </Box>
                  </>
                  :
                  <Card >
                    <CardActionArea>
                      <CardMedia
                        sx={{
                          borderRadius: '1rem',
                          padding: '1px',
                          position: 'relative',
                          overflow: 'hidden',
                          '&:hover .play-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box style={{
                          position: 'relative',
                          // padding: '1rem'
                        }}>
                          {uploadMode == 'video' ? (<>
                            <video
                              style={{
                                width: '100%',
                                height: '220px'
                              }}
                              src={url}
                              alt="video alt text"
                              controls
                            />
                          </>) : (<>

                            <audio
                              src={url}
                              style={{
                                width: '100%',
                                height: '220px'
                              }}
                              controls
                              onPlay={togglePlay}
                              onPause={togglePlay}
                            />
                          </>)}

                        </Box>
                      </CardMedia>
                    </CardActionArea>
                    <Box sx={{ p: 1, display: 'flex' }}>
                      <Box>
                        <Typography variant='body2'>Video Link: <Link>{url}</Link></Typography>
                        <Box sx={{ display: 'flex' }}><Box><Typography variant='body2'>Uploaded: {formatDate(videoDetail?.updated_at)}</Typography></Box> <Box sx={{ mx: 0.5 }}>|</Box> <Box><Typography variant='body2'>By {userDetail.userName + ' ' + userDetail.lastName}</Typography></Box></Box>
                        {/* <Typography variant='body2'>Uploaded: {formatDate(videoDetail.updated_at)} | By {videoDetail.name}</Typography> */}
                      </Box>
                      {/* <Box sx={{alignItems:'center',display:'flex'}}> */}

                      {loading ?
                        <Box>
                          <CircularProgress />
                        </Box>
                        :
                        <Box sx={{ ml: 1, display: 'flex' }}>
                          <Box onClick={handleCopy} style={{ cursor: 'pointer' }}><ContentCopyIcon /></Box>
                          <Box>
                          <label htmlFor="editFile" style={{ display: 'flex', cursor: 'pointer' }}>
                            <Box sx={{ mb: 1.5, ml: 1 }}><ModeEditIcon /></Box>
                            <input type="file" id="editFile" style={{ display: 'none' }}
                              label="Edit Video" name="editFile" onChange={(e) => onEditFileChangeHandler(e)} accept={allowedTypes} />
                          </label>
                          </Box>
                        </Box>
                      }
                      {/* </Box> */}
                    </Box>
                  </Card>
                }
              </>
            }

            {methodsAndState.selectedItems?.size == 1 && methodsAndState.editFlag?.value != "create" &&
              <Card >
                <CardActionArea>
                  <CardMedia
                    sx={{
                      borderRadius: '1rem',
                      padding: '1px',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover .play-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Box style={{
                      position: 'relative',
                      // padding: '1rem'
                    }}>
                      <video
                        style={{
                          width: '100%',
                          // height: '220px'
                        }}
                        src={video}
                        alt="video alt text"
                        controls
                      />
                    </Box>
                  </CardMedia>
                </CardActionArea>
              </Card>
            }

            <Box sx={{ my: 2 }}>
              <Typography variant="subtitle1">Add Thumbnail</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <form> */}
                {/* <label htmlFor="fileInput">Choose a file:</label> */}
                <Card sx={{
                  border: '1px dashed', height: '92px', // Set height to 100%
                  // width:"122px",
                  cursor: 'pointer',
                  alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', padding: '10px'
                }}>
                  <label htmlFor="fileInput" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', }}>
                    {/* <Avatar sx={{ marginRight: '8px',p:2 }}><ImageOutlinedIcon /></Avatar>
        upload thumbnail */}
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', marginRight: '8px', backgroundColor: '#1976D21F' }}>
                      <ImageOutlinedIcon color="primary" />
                    </Avatar>
                    <Link><Typography variant="body1">upload thumbnail *</Typography></Link>
                  </label>
                </Card>
              </Grid>

              <input
                type="file"
                id="fileInput"
                required
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept=".png, .jpg, .jpeg,.gif"
              />
              {/* </form> */}

              <Grid item xs={6}>
                {selectedFile && (
                  <Card sx={{
                    textAlign: 'center',
                    height: '92px', // Set height to 100%
                    // width:"122px",
                    display: 'flex',
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'center', // Center children vertically
                    alignItems: 'center',
                  }}>
                    {/* <p>Selected File: {selectedFile.name}</p> */}
                    <img
                      // src={URL.createObjectURL(selectedFile)}
                      src={selectedFile}
                      alt="Selected"
                      style={{
                        width: '123px',
                        height: '82px',
                        objectFit: 'cover',
                      }}
                    />
                    {/* <button onClick={handleRemoveImage}>Remove Image</button> */}
                  </Card>
                )}
              </Grid>
            </Grid>

            <FormControlLabel sx={{ pt: 1.5 }} required control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />} label={uploadMode == 'video' ? "Add this as a recommended video" : "Add this as a recommended Audio"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box>
                <TextField
                  required
                  id="outlined-required"
                  label="Title"
                  value={titleValue}
                  onChange={handleTitleValue}
                  sx={{ width: '100%' }}
                  error={errorMessage}
                />

                {errorMessage &&
                  //  <Box>{errorMessage}</Box>
                  <Alert variant="none" sx={{ color: "red" }} severity="error">
                    {errorMessage}
                  </Alert>
                }
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Description"
                  value={descriptionValue}
                  onChange={handleDescriptionValue}
                  // size="small"
                  multiline
                  rows={3}
                  sx={{ width: '100%' }}
                />

              </Box>
              <Box sx={{ mt: 2 }}>

                <FormControl
                  // size="small"
                  fullWidth>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={playlistData}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    value={playlist}
                    onChange={handleChangePlaylist}
                    renderInput={(params) => (
                      <TextField {...params} label="Playlist" placeholder="Playlist" />
                    )}
                    ListboxComponent={CustomListbox}
                  />
                </FormControl>
              </Box>
              {uploadMode == 'video' && (<>
                <Box sx={{ mt: 2 }}>
                  <FormControl required fullWidth>
                    <InputLabel id="demo-select-small-label">Category</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={Category}
                      label="Category"
                      onChange={handleChangeCategory}
                      sx={{ width: '100%' }}
                    >
                      {categoryData.map((d) => {
                        return (
                          <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </>)}
              {uploadMode == 'video' && (<>
                <Box sx={{ mt: 2 }}>
                  <FormControl required fullWidth>
                    <InputLabel id="demo-select-small-label">Subcategory</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={Subcategory}
                      label="Subcategory"
                      onChange={handleChangeSubcategory}
                      sx={{ width: '100%' }}
                    >

                      {subcategoryData.map((d) => {
                        return (
                          <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </>)}

            </Box>
          </Grid>
        </Grid>
      </Box>



      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1">Other Details</Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={tags}
                // size="small"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                filterSelectedOptions
                freeSolo
                value={selectedTags}
                onChange={handleTagChange}
                renderTags={(filteredValues, getTagProps) =>
                  filteredValues.map((option, index) => (
                    <Chip variant="outlined" label={typeof option === 'string' ? option : option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tags" placeholder="Select tags" required />
                )}
              />
              {errorsMessages && (
                <Alert variant="none" sx={{ color: "red" }} severity="error">
                  {errorsMessages}
                </Alert>
              )}
              {characterLimitErr && (
                <Alert variant="none" sx={{ color: "red" }} severity="error">
                  Tags must not exceed 80 characters.
                </Alert>
              )}
            </FormControl>
          </Grid>

          {(categoryList || methodsAndState.editFlag?.value === "create") && categories.map((d, i) => (
            <Grid item xs={12} md={6} key={d.id}>
              {console.log("Majin Buu", {
                categoriesName, d, filterredData: categoriesName.filter(
                  (item) => {
                    let subcategoriesInCategory = d.subCategory.map(v => v.id);
                    console.log({ subcategoriesInCategory, item, d })
                    if (subcategoriesInCategory.indexOf(item.id) != -1) {
                      return item.id;
                    }
                  }
                )
              })}
              <FormControl required fullWidth>
                <Autocomplete
                  id={`${d.id}-autocomplete`}
                  multiple
                  limitTags={2}
                  onChange={(e, values) => handleAutocompleteChange(values, d.name)}
                  defaultValue={categoriesName.filter(
                    (item) => {
                      let subcategoriesInCategory = d.subCategory.map(v => v.id);
                      console.log('a9', { subcategoriesInCategory, item, d })
                      if (subcategoriesInCategory.indexOf(item.id) != -1) {
                        console.log('a9', { id: item.id })
                        return item.id;
                      }
                    }
                  )}
                  options={d.subCategory}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params, index) => (
                    <TextField {...params} variant="outlined" label={d.name} required />
                  )}
                  isOptionEqualToValue={isOptionEqualToValue}
                />
              </FormControl>
            </Grid>
          ))}

        </Grid>
      </Box>
      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '2rem', height: '4rem' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
          <Button variant="contained" size="small"
            // disabled={
            //   titleValue.length == '' ||
            //   descriptionValue.length == '' ||
            //   (!selectedFile || !fileName) && !video
            //   // && !is_last
            //   // (!imageThumb ) && !video
            // }
            disabled={disableSubmitButton}
            onClick={() => handleUploadVideoDetails(false)}>SUBMIT</Button>
          <Button variant="outlined" size="small"
            // disabled={
            //   titleValue.length == '' ||
            //   descriptionValue.length == '' ||
            //   (!selectedFile || !fileName) && !video
            //   // && !is_last
            //   // (!imageThumb ) && !video
            // }
            disabled={disableSubmitButton}
            onClick={() => { handleUploadVideoDetails(true); }}>SAVE & NEXT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
        </Stack>
      </Box>
      <SharedDialog id="createPlaylistDialog">
        <CreatePlaylistDialog onUpdatePlaylist={updatePlaylistData} uploadMode={uploadMode ==='video' ? 'video' : 'audio'}/>
      </SharedDialog>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Link copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
    // </MyContext.Provider>
  )
}

export default Details