import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Slide, Box, LinearProgress, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MyContext from '../../pages/KnowledgeManagement/Mycontext';
import { closeDownloadBar } from './DownloadBarSlice';
import { stopDownload } from './DownloadBarSlice';
import { openDialog, closeDialog } from "../dialogBox/dialogBoxSlice";
import SharedDialog from '../dialogBox/dialogBox';

function DownloadBarDemo({ percentage = 0, open, setOpen, name, handleClose }) {
  useEffect(() => {
    console.log("Dialog is here", { percentage, open });
  }, [percentage, open]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{ minWidth: '10rem !important' }}
      open={open}
      TransitionComponent={Slide}
      action={[
        <IconButton key="close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ]}
      onClose={handleClose}
    >
      <Alert
        sx={{ backgroundColor: 'white', border: "1px solid #1D76FE" }}
        variant="filled"
        onClose={handleClose}
      >
        <Box className="linearProgressColor">
          {name}
          <Box sx={{ display: 'flex' }}>
            <LinearProgress style={{ height: 20, borderRadius: 10, width: '10rem' }} variant="determinate" value={percentage} />
            <Typography variant="body2" color="textPrimary" component="span" sx={{ fontWeight: 'bold', marginLeft: '.5rem' }}>
              {percentage}%
            </Typography>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}

DownloadBarDemo.propTypes = {
  percentage: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function DownloadBar({ context = MyContext }) {
  const isOpen = useSelector((state) => state.downloadBar?.isOpen);
  const percentage = useSelector((state) => state.downloadBar?.percentage);
  const dispatch = useDispatch();

  const methodsAndState = useContext(context);

  const [matchingData, setMatchingData] = useState(methodsAndState.dashboardList?.filter?.(item => methodsAndState.selectedItems.has(item.id)));
  console.log("isOpen", {selectedItems: methodsAndState.selectedItems, dashboardList: methodsAndState.dashboardList })
  useEffect(() => {
    let matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
    setMatchingData(matchingData);
    
  }, [methodsAndState.selectedItems])

  useEffect(() => {
    setMatchingData(methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id)));
    console.log("isOpen", {selectedItems: methodsAndState.selectedItems, dashboardList: methodsAndState.dashboardList })
  }, [isOpen]);

  // useEffect(() => {
  //   setMatchingData(methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id)));
  //   console.log("isOpen", {selectedItems: methodsAndState.selectedItems, dashboardList: methodsAndState.dashboardList })
  // }, []);

  const handleClose = () => {
    dispatch(closeDownloadBar());
    dispatch(stopDownload());
    dispatch(openDialog);
  };

  return (
    <>
      {
        matchingData?.[0]?.name && (
          <DownloadBarDemo
            id="isOpen"
            percentage={percentage}
            open={isOpen}
            name={matchingData?.[0]?.name}
            setOpen={dispatch}
            handleClose={handleClose}
          />
        )
        
      }
    </>
  );
  <SharedDialog>dv</SharedDialog>
}

export default DownloadBar;
