import React, { useContext, useState, useEffect } from 'react';
import ApiService from '../../utils/ApiService';
import { Chip, Stack, Button, useMediaQuery, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { removeSearch, searchData, searchValueSet } from '../../features/headerSearch/headerSearch';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from './Mycontext';
import { loaderData } from '../../features/loader/loaderDashboard';
import { useTheme } from '@mui/material/styles';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';

const RecentTags = () => {
  const [chipColor, setChipColor] = useState(null);
  const dispatch = useDispatch();
  const searchValueState = useSelector((state) => state.search?.searchValue);
  const methodsAndState = useContext(MyContext);
  const recentTags = useSelector((state) => state.recentTag?.recentTags);
  const theme = useTheme();
  const navigate = useNavigate();


  const handleMoreTag = () => {
    navigate('/tags');
    dispatch(setCurrentRoute('/tags'));
  };

  const handleColorHandler = (data, index) => {
    if (chipColor !== index) {
      dispatch(loaderData(true));
      setChipColor(index);
      dispatch(searchValueSet(data.name.en));
    } else {
      setChipColor(null);
      dispatch(removeSearch(''));
    }
  };

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const getCharacterLimit = () => {
    if (isXs) return 10;
    if (isSm) return 15;
    if (isMd) return 30;
    if (isLg) return 40;
    if (isXl) return 60;
    return 70; // Default for larger screens
  };

  const characterLimit = getCharacterLimit();

  const getVisibleTags = () => {
    if (isXs) return recentTags?.slice(0, 4);
    if (isSm) return recentTags?.slice(0, 5);
    if (isMd) return recentTags?.slice(0, 8);
    if (isLg) return recentTags?.slice(0, 10);
    return recentTags;
  };

  const visibleTags = useMemo(() => getVisibleTags(), [isXs, isSm, isMd, isLg, recentTags]);


  // const visibleTags = useMemo(() => {
  //   if (!recentTags || recentTags.length === 0) return [];
  //   return recentTags.slice(0, 10); // Show only first 10 tags initially
  // }, [recentTags]);

  const remainingTagsCount = useMemo(() => {
    if (!recentTags || recentTags.length === 0) return 0;
    return recentTags.length - visibleTags.length;
  }, [recentTags, visibleTags]);

  return (
    <Stack id="tagsContainer" direction="row" spacing={1}>
      {visibleTags.map((tag, index) => (
        <Chip
          label={`${tag?.name}`}
          key={index}
          color={chipColor === index ? 'primary' : 'default'}
          onClick={() => handleColorHandler(tag, index)}
          sx={{ borderRadius: '5px' }}
        />
      ))}
      {remainingTagsCount > 0 && (
        <Button onClick={handleMoreTag} sx={{ mx: 1, borderRadius: '8px' }}>
          +{remainingTagsCount} more
        </Button>
      )}
    </Stack>
  );
};

export default RecentTags;
