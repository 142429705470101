import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Tabs, Tab, Typography, Card, Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import MyContext from './Mycontext';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TransitionsSnackbar from './Constantfile/Snackbar';
import PropTypes from 'prop-types';
import ApiService from '../../utils/ApiService';
// import VideoLibraryGridandTree from './VideoLibraryGridandTree';
import AllVideoGrid from './AllGridView';
import PageName from '../../utils/getPageName';
import checkboxHelper from './checkboxHelper';
import { loaderData } from '../../features/loader/loaderDashboard';
import { TagCloud } from 'react-tagcloud';
import DownloadBar from '../../features/downloadBar/DownloadBar';
import advertisment from '../../assets/images/advertisment.jpg';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SearchPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(0);
    const searchValue = useSelector((state) => (state.search?.searchValue));
    const [requestedData, setRequestedData] = useState({});
    const [dashboardList, setDashboardList] = useState([]);
    const keyInSearchApi = ['video', 'audio', 'playlist', 'tag'];
    const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(
        checkboxHelper()
    );

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    // const selectRef = useRef();
    const headerRef = useRef();
    const element = document.querySelector('#headerWidthListner');
    const [widthHeader, setWidthHeader] = useState("100%");
    // const listener = useRef(null);

    // useEffect(() => {
    //     const observer = new ResizeObserver((entries) => {
    //         for (const entry of entries) {
    //             const { width, height } = entry.target.getBoundingClientRect();
    //             setWidthHeader((parseInt(width) - 10) + 'px');
    //         }
    //     });
    //     observer.observe(headerRef.current);

    //     return () => {
    //         observer.disconnect();
    //     };
    // }, [element]);


    function formatData(data) {
        switch (currentTab) {
            case 0: return {
                ...data
            }
            case 1: return {
                "id": data?.id,
                ...data,
                "name": data?.name,
                "date": data?.updated_at,
                'created_at': data?.created_at,
                'numVideos': data?.videoPlaylist?.length,
                'thumbNailImage': data?.thumbNailImage
            }
            case 2: return {
                ...data
            }
            case 3: return {
                ...data
            }
        }

    }

    const apiCall = () => {
        let formData = {};
        if (!searchValue.length) {
            return;
        }
        formData[keyInSearchApi[currentTab]] = searchValue;
        dispatch(loaderData(true))
        ApiService.get('videoSearch', formData).then((res) => {
            // let data = res.data[keyInSearchApi[currentTab]];
            let data = res.data.data;
            if (data) {
                dispatch(loaderData(false))
                data = data.map(v => formatData(v));
                let requestedDataTemp = { ...requestedData };
                requestedDataTemp[keyInSearchApi[currentTab]] = data;
                setRequestedData(requestedDataTemp);
                setDashboardList(data);
                dispatch(loaderData(false))

            }
            console.log("z1",{res,data})
        }).catch((error) => {
            console.log(error);
            // setLoading(false)
            dispatch(loaderData(false))
            setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }

    let timeoutId = null; // Initialize a variable to store the timeout ID

    const callApiResolver = () => {
        if (!timeoutId) { // Check if there's no pending timeout
            timeoutId = setTimeout(() => {
                apiCall();
                timeoutId = null; // Clear timeout ID after the call
            }, 1000); // Set timeout to 2 seconds (2000 milliseconds)
        }
    };

    useEffect(() => {
        callApiResolver();
    }, [])

    useEffect(() =>{
        if(requestedData?.[keyInSearchApi[currentTab]]){
            if(currentTab != 3){
                setDashboardList(requestedData[keyInSearchApi[currentTab]]);
            }
        } else {
            callApiResolver();
        }
    }, [currentTab])

    useEffect(() => {
        setRequestedData({});
        callApiResolver();
    }, [searchValue])

    const getColor = (tag, maxSize, minSize) => {
        // Example: Set color based on tag count
        let count = tag?.videos?.length ?? 0;
        const color = count === 0
            ? 'grey'
            : count >= 1 && count <= 20
                ? 'rgb(86, 66, 163)' : count >= 20 && count <= 50 ? 'rgb(29, 121, 249)'
                    : 'rgb(8, 118, 145)';
        return color;
    };

    const computeTagSizes = (allTags) => {
        const minSize = 16;
        const maxSize = 45;

        const maxCount = Math.max(...allTags.map((tag) => tag?.videos?.length ?? 0));
        const minCount = Math.min(...allTags.map((tag) => tag?.videos?.length ?? 0));

        return allTags.map((tag, index) => ({
            ...tag,
            count: tag?.videos?.length ?? 0,
            value: tag?.name,
            id: tag?.name + index,
            size: minSize + ((maxSize - minSize) * ((tag?.videos?.length ?? 0) - minCount)) / (maxCount - minCount),
            color: getColor(tag),
        }));
    };

    // ------------------- CONTEXT API METHODS AND STATES ---------------------
    const methodsAndState = {
        dashboardList, setDashboardList, snackBar, setSnackBar, selectedItems, setSelectedItems, headerCheckStatus, setHeaderCheckStatus, showCode, setShowCode, checkboxGridHelper, setCheckboxGridHelper
    };

    return (
            <>
        <MyContext.Provider value={methodsAndState}>
            <Box sx={{my:2}}>
                <Typography variant='h6'> Search Result for {searchValue} </Typography>
            </Box>
            <Card>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleChange} aria-label="Search Result Tabs">
                        <Tab label="Videos" {...a11yProps(0)} />
                        <Tab label="Audios" {...a11yProps(1)} />
                        <Tab label="Playlists" {...a11yProps(2)} />
                        <Tab label="Tags" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={currentTab} index={0}>
                    <AllVideoGrid checkboxRequied={false} isPinned={false} page={PageName.AllVideos} gridValue={{ lg: 4, md: 6, sm: 6 }}/>
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1}>
                    <AllVideoGrid checkboxRequied={false} isPinned={false} page={PageName.AllAudios} gridValue={{ lg: 4, md: 6, sm: 6 }}/>
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={2}>
                    <AllVideoGrid checkboxRequied={false} isPinned={false} page={PageName.PlayList} gridValue={{ lg: 4, md: 6, sm: 6 }}/>
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={3}>
                    {console.log("Indian Army",{api: requestedData?.[keyInSearchApi[3]],currentTab,requestedData})}
                {requestedData?.[keyInSearchApi[3]]?.length ? (<>
                                <TagCloud
                                    minSize={20}
                                    maxSize={40}
                                    // tags={allTags}
                                    tags={computeTagSizes(requestedData?.[keyInSearchApi[3]] ?? [])}
                                    renderer={(value, tag) => (
                                        <span key={value.value} style={{ width: value.length > 55 ? '55%' : '', wordBreak: value.length > 55 ? 'break-word' : '', color: getColor(value), fontSize: `${value.size}px` }}>                                           
                                        {value.value}
                                        </span>
                                    )}
                                    // onClick={onWordClickHandler}
                                />
                            </>) : (<>
                                <Typography sx={{ textAlign: "center", width: "100%" }} >No Data Found </Typography>
                            </>)}
                </CustomTabPanel>
            </Box>
            </Card>
            <TransitionsSnackbar />
            <DownloadBar context={MyContext} />
        </MyContext.Provider >
            </>
    )
}

export default SearchPage;