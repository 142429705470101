import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, Skeleton, Button, IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import TableComponent from '../../features/TableComponent/TableComponent';
import ApiService from '../../utils/ApiService';
import moment from 'moment';
import MyContext from './Mycontext';
import checkboxHelper from './checkboxHelper';
import Checkbox from '@mui/material/Checkbox';
import VideoLibraryTools from './VideoLibraryTools';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import ImageType from './ImageType';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setPageNo, setScrollFlag } from '../../features/dashboardData/dashboardData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinUnpin from './PinUnpin';
import unpin from '../../assets/images/KM-Manager/unpin.svg';
import PushPinIcon from '@mui/icons-material/PushPin';
import MenuBar from './menuBar';
import { setCategoryDataList } from '../../features/commonDataSlice/categoryData';
import throttle from '../../utils/throttle';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import DownloadBar from '../../features/downloadBar/DownloadBar';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2563EB',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000000',
        fontWeight: '400',
        boxShadow: '-1px 0px 6px #aaa',
        // padding: "15px",
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #2563EB',
        borderRadius: 2,
    },
}));

const RecentFileTableView = () => {
    const _ = require("lodash");
    const methodsAndState = useContext(MyContext);
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [loading, setLoading] = React.useState(true)
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(checkboxHelper());
    const [showCode, setShowCode] = useState(false);
    // const { fetchData } = InfiniteScrollData();
    const [metaData, setMetaData] = useState();
    const dashboardScroll = useSelector((state) => (state.dashboardScroll));
    const categoryListData = useSelector((state) => (state.category));
    const [checkedData, setCheckedData] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pinUnpinHandler } = PinUnpin();
    const isMobileView = window.innerWidth < 933; // Adjust the breakpoint as needed
    const userList = useSelector((state) => state?.user?.userListName)
    // const debaunceThrottle = throttle();
    const [disablePinBtn, setDisablePinBtn] = useState(false);

    React.useEffect(() => {
        if (methodsAndState.dashboardList.length) {
            methodsAndState.checkboxGridHelper.init(methodsAndState.dashboardList, methodsAndState.setSelectedItems, setHeaderCheckStatus, setShowCode)
        }
    }, [methodsAndState.dashboardList]);
    
    // useEffect(() => {
    //     if(disablePinBtn){
    //         setTimeout(() => {
    //             setDisablePinBtn(false);
    //         }, 1000);
    //     }
    // }, [disablePinBtn])
    
    const checkBoxHandler = (data) => {
        methodsAndState.checkboxGridHelper.checkboxListener(data.id)
        setCheckedData(data)
        methodsAndState.setSelectedId({ id: data.id, value: "checkbox", type: data.type, name: data.name, delete: "notall" })
    }

    const recentApiCall = () => {
        if(!userList?.userId){
            return;
        }
        if (dashboardScroll.pageNo + 1 == 1) {
            setLoading(true)
        }
        let params = {
            limit: 30,
            page: dashboardScroll.pageNo + 1,
            userId: userList?.userId,
            // userId: 1,
            related: 'user',

        }
        console.log({params});
        ApiService.get('filemanagerActivity', params).then(res => {
            let data = res.data.data
            setLoading(false)
            dispatch(setPageNo(res.data.meta.current_page))
            setMetaData(res.data.meta)
            if (data) {
                if (dashboardScroll.scrollFlag) {
                    methodsAndState.setDashboardList([...methodsAndState.dashboardList, ...data.map((d) => formatData(d))])
                    // methodsAndState.setFileCount(methodsAndState.dashboardList.length + data.length)
                } else {
                    methodsAndState.setDashboardList(data.map((d) => formatData(d)))
                    // methodsAndState.setFileCount(data.length)
                }
                console.log({data: data.map((d) => formatData(d))})
            }
            methodsAndState.setFileCount(res?.data?.meta?.total)
        }).catch((error) => {
            console.error(error);
            setLoading(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }

    useEffect(function () {
        recentApiCall()
        if (categoryListData.categoryList.length == 0) {
            ApiService.get('category').then(res => {
                let response = res.data.data
                if (response) {
                    // methodsAndState.setCategoryList(response)
                    dispatch(setCategoryDataList(response))
                }
            })
        }
    }, [categoryListData])

    function formatDateTime(dateTime) {
        const now = moment();
        const targetDateTime = moment(dateTime);
        const diffDuration = moment.duration(now.diff(targetDateTime));
        const days = diffDuration.days();
        const hours = diffDuration.hours();
        const minutes = diffDuration.minutes();

        if (days >= 7) {
            return targetDateTime.format("MMMM D");
        } else if (days >= 1) {
            return targetDateTime.format("dddd [at] h:mm A");
        } else if (hours >= 1 && hours < 10) {
            return targetDateTime.format(`${hours} [hours]`);
        } else if (hours >= 10) {
            return targetDateTime.format("h [hours]");
        } else if (minutes >= 1 && minutes < 10) {
            return targetDateTime.format(`${minutes} [minutes]`);
        }  else if (minutes >= 1) {
            return targetDateTime.format("m [minutes] ");
        } else {
            return "Just now";
        }
    }

    function formatBytes(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Bytes';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
    }

    function formatData(data) {
        const tempData = {
            "id": data.id,
            // "id": (data.media == null || data.media.deleted_at != null) ? 'file_undefined' : (data?.mediaType == "directory" ? 'folder_' : 'file_') + data?.mediaId,
            // "id": (data.media == null) ? 'file_undefined' : (data?.mediaId == "directory" ? 'folder_' : 'file_') + data?.mediaId,
            "mediaId": data?.mediaId,
            "name": data?.media?.name,
            "tags": data.media?.deleted_at == null ? data?.media?.tags : [],
            "mediaType": data?.mediaType,
            "type": data?.mediaType,
            "date": formatDateTime(data?.media.created_at),
            "owner": data.media?.deleted_at == null ? data?.media?.createBy?.name : '',
            "pinned": data?.pinned,
            "fileSize": data?.media?.size != null && data.media?.deleted_at == null ? formatBytes(data?.media?.size) : "--",
            "disable": data.media == null || data.media.deleted_at != null,
            "activityName": data.activityName
            // "fileSize":  data?.media?.size
        }
        return tempData;
    }

    const handleMoreTag = () => {
        navigate('/tags');
    };

    const pinUnpinSingleHandler = (e, data, noClear) => {
        e.stopPropagation();
        setDisablePinBtn(true);
        selectionCheckboxClear(data, noClear)
        pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, data.pinned ? "Unpin" : "Pin", methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList, setDisablePinBtn);

    }
    const selectionCheckboxClear = (data, noClear) => {
        if (!noClear) {
            methodsAndState.checkboxGridHelper.clearSelection()
            methodsAndState.checkboxGridHelper.checkboxListener(data.id);
        }
        methodsAndState.selectedItems.clear()
        methodsAndState.selectedItems.add(data.id)
    }

    const buttonSX = {
        '&': { color: '#00000087' },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
    }

    const desktopColumns = [
        {
            id: 'select', label: '', headerTemplate: (row) => {
                return (<>
                    <Checkbox onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} />
                </>)
            }, headerName: 'Type', minWidth: 70, flex: 1, template: (key) => {
                return (<>
                    <Checkbox
                        disabled={key.disable}
                        onClick={() => checkBoxHandler(key)}
                        checked={methodsAndState.selectedItems.has(key.id)}
                    />
                </>)
            }
        },
        {
            id: 'name', field: 'name', label: 'Name', minWidth: 300, flex: 1, template: (row) => {
                return (<Box className={row.disable ? "disabled-container" : ""} sx={{ display: "flex", height: 30, textWrap: "nowrap", cursor: "pointer", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", height: 30, width: 30, textWrap: "nowrap",alignItems: "center"  }}>
                        <ImageType data={row} isRecentFiles={true} />
                        <HtmlTooltip title={row?.name} arrow>
                            <Box sx={{ alignSelf: 'center', padding: '0 5px', marginLeft: "11px" }}>
                                {row?.name && row.name.length > 20 ? row.name.slice(0, 20) + '...' : row?.name}
                            </Box>
                        </HtmlTooltip>
                    </Box>
                    {!row.disable ? <Box sx={{ ml: '2.3rem', mt: '0.2rem' }} onClick={() => selectionCheckboxClear(row)} >
                        <IconButton onClick={(e) => {
                            // debaunceThrottle.throttle(() => {
                                pinUnpinSingleHandler(e, row, true)
                            //   }, 1000)
                        }} 
                        disabled={disablePinBtn}
                        sx={buttonSX} aria-label="pinunpin">
                            {row.pinned || window.location.pathname == "/pinned" ? <img src={unpin} /> : <PushPinIcon />}
                        </IconButton>
                    </Box> : null}
                </Box>)
            }
        },
        { id: 'date', field: 'date', label: 'Activity', minWidth: 70, flex: 1, template: (row) => {
            return (<Box>
                <Box>{row.activityName}</Box>
                <Box><Typography variant="caption">{row.date}</Typography></Box>
            </Box>)
        } },

        {
            id: 'tags',
            label: 'Tags',
            headerName: 'Tags',
            minWidth: 130,
            flex: 1,
            template: (row) => {
                const tags = row.tags || [];
                const visibleTags = tags.slice(0, 2);
                const remainingTags = tags.slice(2);
                return (
                    <Box
                        onClick={handleMoreTag}
                    >
                        {visibleTags.map((tag, index) => (
                            <React.Fragment key={tag.id}>
                                <Chip
                                    sx={{ margin: '5px', borderRadius: '8px' }}
                                    label={tag.name.en}
                                />
                                {/* {tag.name.en} */}
                                {index < visibleTags.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                        {remainingTags.length > 0 && (
                            <HtmlTooltip title={remainingTags.map((tag) => tag.name.en).join(', ')} arrow>
                                <span sx={{ mx: 2 }}>
                                    <Button >{`+${remainingTags.length}`}</Button>
                                </span>
                            </HtmlTooltip>
                        )}
                    </Box>
                );
            },
        },
        { id: 'owner', field: 'owner', label: 'Owner', minWidth: 130, flex: 1 },

        { id: 'fileSize', field: 'fileSize', label: 'File Size', minWidth: 130, flex: 1 },

        // { id: 'action', field: '', label: '', minWidth: 70, flex: 1 },
        {
            id: '3dots', label: '', headerName: '', minWidth: 130, flex: 1, template: (row) => {
                return (<Box onClick={() => selectionCheckboxClear(row)}>
                    {!row.disable ? <MenuBar data={checkedData} value={true} isRecentFiles={true} /> : null}
                </Box>)
            }
        }
    ];

    // Define your columns for both mobile and desktop views
    const mobileColumns = [
        {
            // Only show 'name' column for mobile view
            // Define all your columns for the desktop view

            id: 'select', label: '', width: 30, headerTemplate: () => {
                return (<>
                    <Checkbox onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} />
                </>)
            }, headerName: 'Type', flex: 1, template: (key) => {
                return (<>
                    <Box>
                        <Checkbox
                            disabled={key.disable}
                            onClick={() => checkBoxHandler(key)}
                            checked={methodsAndState.selectedItems.has(key.id)}
                        />

                    </Box>
                </>)
            }
        },
        {
            id: 'name', field: 'name', label: 'Name', width: 150, flex: 1, template: (row) => {
                return (<Box className={row.disable ? "disabled-container" : ""} sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start", justifyContent: "space-between", flexDirection: 'column' }}>
                    <Box sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start" }}>
                        <ImageType data={row} />
                        <HtmlTooltip title={row?.name} arrow>
                            <Box sx={{ alignSelf: 'center', padding: '0 5px', fontSize: '12px' }}>
                                {row?.name && row.name.length > 20 ? row.name.slice(0, 20) + '...' : row?.name}
                            </Box>
                        </HtmlTooltip>

                    </Box>

                    {/* Type */}
                    <Box sx={{ display: 'flex' }} >
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            Owner:
                            <Typography variant='span' className='mobileViewStyle' >{row.owner}</Typography>
                        </Box>
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            File Size:
                            <Typography variant='span' className='mobileViewStyle' >{row.fileSize}</Typography>
                        </Box>
                        {!row.disable ? <Box onClick={() => selectionCheckboxClear(row)} >
                            <HtmlTooltip title={row.pinned ? 'Unpin' : 'Pin'}>
                                <IconButton onClick={(e) => {
                                    // debaunceThrottle.throttle(() => {
                                        pinUnpinSingleHandler(e, row, true)
                                    // }, 1000)
                                }} 
                                disabled={disablePinBtn}
                                sx={buttonSX} aria-label="pinunpin">
                                    {row.pinned || window.location.pathname == "/pinned" ? <img src={unpin} /> : <PushPinIcon />}
                                </IconButton></HtmlTooltip>
                        </Box> : null}
                    </Box>
                    <Box>
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            Activity:
                            <Typography variant='span' className='mobileViewStyle' >
                                <Box>{row.activityName}</Box>
                            </Typography>
                            <Box><Typography variant="caption" sx={{fontWeight: '400'}}>{row.date}</Typography></Box>
                        </Box>
                    </Box>


                </Box>)

            }

        },
        {
            id: 'tags',
            label: 'Tags',
            headerName: 'Tags',
            minWidth: 130,
            flex: 1,
            template: (row) => {
                const tags = row.tags || [];
                const visibleTags = tags.slice(0, 2);
                const remainingTags = tags.slice(2);

                return (
                    <Box onClick={handleMoreTag}>
                        {visibleTags.map((tag, index) => (
                            <React.Fragment key={tag.id} >
                                <Chip
                                    sx={{
                                        margin: '5px', borderRadius: '8px', cursor: 'pointer', '& .MuiChip-label': {
                                            fontSize: '12px',
                                        }
                                    }}
                                    label={tag.name.en}
                                />
                                {index < visibleTags.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                        {remainingTags.length > 0 && (
                            <HtmlTooltip title={remainingTags.map((tag) => tag.name.en).join(', ')} arrow>
                                <span sx={{ mx: 2 }}>
                                    <Button >{`+${remainingTags.length}`}</Button>
                                </span>
                            </HtmlTooltip>
                        )}
                    </Box>
                );
            },
        }, {
            id: '3dots', label: '', headerName: '', minWidth: 130, flex: 1, template: (row) => {
                return (<Box onClick={() => selectionCheckboxClear(row)}>
                    {!row.disable ? <MenuBar data={checkedData} value={true} isRecentFiles={true} /> : null}
                </Box>)
            }
        },
    ];

    const fetchData = () => {
        if (metaData?.last_page > metaData?.current_page) {
            dispatch(setScrollFlag(true))
            recentApiCall()
        }
    }

    return (
        <Box sx={{ my: 3 }}>
            {(loading) ?
                (<>
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                </>
                ) :
                (
                    <Box sx={{ position: 'relative' }}>
                        <InfiniteScroll
                            dataLength={methodsAndState.dashboardList.length}
                            next={fetchData}
                            hasMore={true} // Replace with a condition based on your data source
                            loader={(metaData?.last_page > metaData?.current_page) ? <Grid container spacing={2} >
                                {[1, 2, 3, 4].map((d, key) => {
                                    return (
                                        <Box key={key}>
                                            <Skeleton variant="rounded" sx={{ my: "1rem" }} width={1000} height={40} />
                                        </Box>
                                    );
                                })}
                            </Grid> : false}
                        >
                            <Box sx={{ width: '100%' }}>
                                {/* <Box sx={{ position: 'absolute', top: 0, width: '100%', mt: 5 }}> */}
                                <TableComponent data={methodsAndState.dashboardList} columns={isMobileView ? mobileColumns : desktopColumns} isRecentFiles={true} />
                            </Box>
                        </InfiniteScroll>
                    </Box>
                )}
                <DynamicSnackbar />
                <DownloadBar context={MyContext} />
        </Box>
    )
}
export default RecentFileTableView