import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Card, Stack, Grid, ButtonGroup, Button, Typography, Menu, MenuItem, ListItemText, ListItemIcon, ToggleButton, ToggleButtonGroup, InputLabel, FormControl, Select, Chip, FormControlLabel, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import MyContext from './Mycontext';
import AppsIcon from '@mui/icons-material/Apps';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { userPermissions } from './Permission/required.permission';
import Can from './Permission/Can';
import { useSelector } from 'react-redux';
import DashboardFilter from './DashboardFilter';
import gridview from '../../assets/images/KM-Manager/gridview.svg';
import tabularview from '../../assets/images/KM-Manager/tabularview.svg';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ApiService from '../../utils/ApiService';
import { searchValueSet, removeSearchList, searchDataList, removeSearch, } from '../../features/headerSearch/headerSearch';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const DashboardTools = () => {
    const methodsAndState = useContext(MyContext);
    const userPermissionsListRedux = useSelector((state) => (state.permission));
    // const searchList = useSelector((state) => (state.search.searchValue));
    // console.log(searchList)
    const [userPermissionsList, setUserPermissionsList] = useState([]);

    const [alignment, setAlignment] = React.useState('left');
    const currentRoute = useSelector((state) => (state.routing.currentRoute));
    const [query, setQuery] = useState('');

    const dispatch = useDispatch();

    const searchVideos = () => {
        let params = {
            video: query,
        };

        ApiService.get('videoSearch', params).then((res) => {
            let data = res.data.video;
            console.log(data);
            dispatch(searchDataList(data))
            //   dispatch(searchValueSet(query))
        });
    };

    useEffect(() => {
        if (query) {
            searchVideos();
        }
    }, [query]);
    //   const handleSearch = () => {
    //     // Call the parent component's onSearch function with the query
    //     setQuery(query);
    //     console.log(query)
    //   };

    const removeSearch = () => {
        setQuery('')
        dispatch(removeSearchList());
        // dispatch(removeSearch());
    }


    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [alignmentValue, setAlignmentValue] = React.useState('left');

    const handleAlignmentValue = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    useEffect(() => {
        if (userPermissionsListRedux.permissionList) {
            setUserPermissionsList(userPermissionsListRedux.permissionList)
        }
    }, [userPermissionsListRedux])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const isMediumOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const buttonSX = {
        '&': {
            color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)", minWidth: "40px", padding: "5px 10px", '&:hover': {
                color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)"
            },
        },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#000000' }
    }

    return (
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            {/* <Paper

                sx={{ p: '2px 4px', mr: 2, display: 'flex', alignItems: 'center', width: '100%', borderRadius: '50px', background: '#EEF6FF' }}
            >
                <IconButton sx={{ p: '5px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ mx: 1, flex: 1 }}
                    placeholder="Search here"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                // inputProps={{ 'aria-label': 'search videos' }}
                />
                {query && (
                    <IconButton sx={{ p: '5px' }} onClick={removeSearch} aria-label="search">
                        <CloseIcon />
                    </IconButton>
                )}
            </Paper> */}
            {!methodsAndState.tagsFlag ? "" :
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    {currentRoute === '/videos' || currentRoute === '/audio' ? (
                        <Box>
                            {/* {methodsAndState.selectedItems.size == 0 && ( */}
                            <Button
                                // sx={buttonSX}
                                variant="outlined"
                                id="categoryFilter"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                // disabled={ || searchList.searchValue != ""}
                                sx={{
                                    ...buttonSX, '&.Mui-disabled': {
                                        color: "rgba(145, 158, 171, 0.8)",
                                        backgroundColor: ' rgba(145, 158, 171, 0.24)', opacity: 0.3
                                    }
                                }}
                            >
                                <FilterListIcon />
                            </Button>
                            {/* )} */}
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                sx={{ '& .MuiMenu-list': { padding: '1rem' }, width: '100%' }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            // disableRipple={methodsAndState.selectedItems.size > 0}
                            >
                                <DashboardFilter handleClose={handleClose} />
                            </Menu>
                        </Box>
                    ) : null}


                </Box>}
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                <ToggleButtonGroup
                    value={alignment}
                    id='gridTableView'
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{ height: '2.2rem', marginRight: '4px' }}
                >
                    <ToggleButton value="left" aria-label="left aligned" onClick={methodsAndState.handleGridViewClick}>
                        {/* <AppsIcon /> */}
                        <img src={gridview} alt='gridview' />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered" onClick={methodsAndState.handleTreeViewClick}>
                        {/* <TableRowsIcon /> */}
                        <img src={tabularview} alt='tabularview' />
                    </ToggleButton>
                </ToggleButtonGroup>

            </Box>
        </Box>
    );
};

export default DashboardTools;