import React, { useContext, useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './TableComponent.css';
import zIndex from "@mui/material/styles/zIndex";
import { Typography } from "@mui/material";
import { useMediaQuery } from '@mui/material';

const TableComponent = ({ columns, data, isRecentFiles, ...props }) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const isDesktop = useMediaQuery('(max-width: 767px)');
    const [isFixed, setIsFixed] = useState(false);
    // Define your condition (e.g., check if the URL is "pinned")
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // const isPinned = window.location.pathname === "/pinned";

    const filteredColumns = isDesktop
        ? columns.filter((column) => column.id !== "tags" && column.id !== "3dots")
        : columns;
    return (<>
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer className="table-container" sx={{mt:1}}>
                <Table stickyHeader aria-label="sticky table">
                    {/* <Table stickyHeader={isDesktop} aria-label="sticky table"> */}
                    <TableHead sx={{
                        width: { xs: 'calc(100% - 60px) !important',sm: "calc(100% - 125px) !important", md: "calc(100% - 125px) !important" }
                    }}
                        className="sticky-header">
                        <TableRow>
                            {filteredColumns.map((column, index) => {
                                let sx = { '&:nth-of-type(2)': {
                                    paddingLeft: { xs: '10px', sm: '16px',md: "16px", lg:"16px" },
                                }, backgroundColor: '#EEF6FF', padding: '0.5rem 1rem',lineHeight:'2.5rem !important' };
                                if (column.frozenAt) {
                                    sx['left'] = column.frozenAt;
                                }
                                return (
                                    <TableCell
                                        className={column.frozen ? 'sticky' : ''}
                                        sx={sx}
                                        key={index}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, width: column.width, color: "#64748B", zIndex: isFixed ? '0' : '2' }}
                                    >
                                        {column.headerTemplate ? column.headerTemplate() : column.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? data
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {filteredColumns.map((column) => {
                                            const value = row[column.id];
                                            let sx = {
                                                '&:nth-of-type(3)': {
                                                    maxWidth: { xs: '200px', sm: '200px' },
                                                    width: { xs: '85% ', sm: '85% ', lg: "calc(100% - 90%)", xl: "calc(100% - 90%)" },
                                                },
                                                '&:nth-of-type(4)': {
                                                    maxWidth: '100px'
                                                },
                                                p: '.5rem 1rem', borderBottom: '1px solid rgba(0, 0, 0, 0.1) '
                                            };
                                            if (column.frozenAt) {
                                                sx['left'] = column.frozenAt;
                                            }
                                            return (
                                                <TableCell
                                                    className={column.frozen ? 'sticky' : ''}
                                                    sx={sx}
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {
                                                        column.template
                                                            ? column.template(row)
                                                            : value
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }) :
                            //    "No Data Found"
                            <TableRow ><TableCell variant="body">No Data Found</TableCell></TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {!props?.hidePagination &&

                (<TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />)

            } */}
        </Paper>
    </>)
}

export default TableComponent;