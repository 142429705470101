import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar,closeSnackbar } from './snackbarSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const DynamicSnackbar = ({  ...props }) => {
    const dispatch = useDispatch();
    const id = props?.id;
    const handleSnackbarClose = () => {
        dispatch(closeSnackbar());
    };
    // console.log("abc",message)
    // const isOpen = useSelector((state) =>  state.snackbar.isOpen );
    const isOpen = useSelector((state) =>  state.snackbar?.isOpen );
    // console.log('a1',state.snackbar?.isOpen)
    const message = useSelector((state) =>  state.snackbar?.message );
    const severity = useSelector((state) =>  state.snackbar?.severity );
    // console.debug("aaaaaaaaaaa",severity)
    return (
        <>
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleSnackbarClose} sx={{zIndex:"9999 !important"}}>
                <Alert onClose={handleSnackbarClose}  severity={severity} sx={{ width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default DynamicSnackbar