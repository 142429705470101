import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../features/dialogBox/dialogBoxSlice';
import ApiService from '../../../utils/ApiService';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import MyContext from '../Mycontext';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import checkboxHelper from '../checkboxHelper';

export default function DeleteDialog({ context = MyContext }) {
    const methodsAndState = React.useContext(context);
    const [checkedValue, setCheckedValue] = useState(null)
    const [flag, setFlag] = useState(false)
    const dispatch = useDispatch();
    const userList = useSelector((state) => state?.user?.userListName);
    const isPlaylist = window.location.pathname == '/playlist';
    const isRecommanded = window.location.pathname == "/recommended";
    const [uploadMode, setUploadMode] = useState(methodsAndState.uploadMode ?? 'video');
    console.log('a3', uploadMode)

    let uniqueArray = [];

    useEffect(() => {
        if(isRecommanded){
            let matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
            if(matchingData[0]){
                setUploadMode(matchingData[0]?.audioUrl ? 'audio' : 'video')
            }
        }
    }, [])

    const closeDialogPublish = (id) => {
        dispatch(closeDialog(id));
    }

    function deleteResponse(ids) {
        console.log({ ids })
        let params = {
            ids: ids,
            // fids: paramsfileIds,
            userId: userList?.userId
        }
        // let checkedValue = checkboxHelper().snackBarMessageHandler(fileIds, folderIds)
        let checkedValue = isPlaylist ? 'Playlist' : (uploadMode === "video" ? "Video" : "Audio");
        console.log('a1', {checkedValue,isPlaylist,uploadMode})
        if (ids.length > 1) {
            checkedValue = checkedValue + 's';
        }
        setFlag(true)
        let api = isPlaylist ? 'playListSoftDelete' : (uploadMode === "video" ? "fileFolderSoftDelete" : "audioDelete");
        ApiService.delete(api, params).then(res => {
            setFlag(false)
            methodsAndState.setSelectedItems(new Set([]))
            // methodsAndState.setSnackBar({dataLoad: true, message: res.data.title ?? (uploadMode === "video" ? "Video" : "Audio") + " has been successfully deleted.", severity: "info" });
            methodsAndState.setSnackBar({ dataLoad: true, message: `${checkedValue + " has been successfully deleted."}`, severity: "info" })
            // let mergefolderfile = [...folderIds, ...fileIds]
            let deleteData = methodsAndState.dashboardList.filter(v => !ids.includes(v.id))
            methodsAndState.setDashboardList(deleteData)
            methodsAndState?.updateMetaData?.(-1 * ids.length);
            methodsAndState.setSelectedId({ id: '0', value: "", name: "", type: "" })
            closeDialogPublish('deleteDialog')
        }).catch((error) => {
            setFlag(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }

    const deleteHandler = () => {
        if (checkedValue != null) {
            console.log('a1', { dashboardList: methodsAndState.dashboardList, selectedItems: methodsAndState.selectedItems, uploadMode: methodsAndState.uploadMode })
            const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id)).map(v => v.id);
            deleteResponse(matchingData)
        }
    }
    return (
        <Box pb="1rem">
            <DialogTitle id="alert-dialog-title">
                {"Delete"}
            </DialogTitle>
            <DialogContent sx={{ padding: '16px 24px' }}>
                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                    Are you sure you want to delete?
                    {/* {methodsAndState.dynamicDeleteMessage} */}
                </DialogContentText>
                <FormGroup sx={{ padding: '20px 0px 0px 0px' }}>
                    <FormControlLabel
                        required
                        control={<Checkbox
                            onChange={(e) => setCheckedValue(e.target.checked)}
                            sx={{ mb: 3 }}
                        />}
                        label={`I Understand that deleting ${isPlaylist ? 'playlist' : (uploadMode === "video" ? "video" : "audio")} from T-Stream is not permanent and send this item to trash.`}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button onClick={() => closeDialogPublish('deleteDialog')} disabled={!flag ? false : true}>
                    CANCEL
                </Button>
                {flag == false ? <Button onClick={deleteHandler} color='primary' variant="contained" disabled={checkedValue ? false : true} >YES, DELETE</Button>
                    : <Button color='primary' variant="contained"> <CircularProgress color={'success'} size="20px" /></Button>}
            </DialogActions>
        </Box>
    );
}