import React, { useState, useEffect, useRef } from 'react'
import { Box, Card, Grid, Stack } from '@mui/material'
import DashboardHeading from '../../../../pages/KnowledgeManagement/DashboardHeading';
import TransitionsSnackbar from '../../../../pages/KnowledgeManagement/Constantfile/Snackbar';
import MyContext from '../../../../pages/KnowledgeManagement/Mycontext';
import { permissionDataList } from '../../../../features/permission/permissionData';
import ApiService from '../../../../utils/ApiService';
import DashboardTools from '../../../../pages/KnowledgeManagement/DashboardTools';
// import AllVideoGrid from './AllGridView';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import FileDashboardKM from '../../../../pages/KnowledgeManagement/DrawerDialogKM/FileDashboardKM';
import SharedDialog from '../../../../features/dialogBox/dialogBox';
import SharedDrawer from '../../../../features/drawers/Drawer';
import RenameDialog from '../../../../pages/KnowledgeManagement/DialogKM/RenameDialog';
import DeleteDialog from '../../../../pages/KnowledgeManagement/DialogKM/DeleteDialog';
import { openDrawer } from '../../../../features/drawers/drawerSlice';
import { openDialog } from '../../../../features/dialogBox/dialogBoxSlice';
import VideoLibraryGridandTree from '../../../../pages/KnowledgeManagement/VideoLibraryGridandTree';
import VideoLibraryTools from '../../../../pages/KnowledgeManagement/VideoLibraryTools';
import checkboxHelper from '../../../../pages/KnowledgeManagement/checkboxHelper';
import { setPageNo, setScrollFlag } from '../../../../features/dashboardData/dashboardData';
import { setCategoryDataList } from '../../../../features/commonDataSlice/categoryData';
import MoveDialog from '../../../../pages/KnowledgeManagement/DialogKM/MoveDialog';
import UploadDrawer from './StreamDrawer/UploadDrawer';
import PageName from '../../../../utils/getPageName';
import DownloadBar from '../../../../features/downloadBar/DownloadBar';
import transformationHelper from '../../../../utils/transformationHelper';

const ViewRecommendedVideo = ( individualAPI = 'recommendedVideo',) => {
  const dispatch = useDispatch();
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dashboardList, setDashboardList] = useState([]);
  const userList = useSelector((state) => state?.user?.userListName)
  const [showGridView, setShowGridView] = useState(true);
  const [showTreeView, setShowTreeView] = useState(false);
  const [tagsFlag, setTagsFlag] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set([]));
  console.log('a1',selectedItems)
  const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
  // console.log('a1',selectedId,selectedItems)
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
  const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
  // const [categoryList, setCategoryList] = useState([]);
  const [isFixedTop, setisFixedTop] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [metaData, setMetaData] = useState();
  const categoryListData = useSelector((state) => (state.category));

  const headerRef = useRef();
  const element = document.querySelector('#headerWidthListner');
  const [widthHeader, setWidthHeader] = useState("100%");
  // const listener = useRef(null);
  const [checkedData, setCheckedData] = useState();
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const dashboardScroll = useSelector((state) => (state.dashboardScroll));
  const [checkboxGridHelper, setCheckboxGridHelper] = useState(
    checkboxHelper()
  );
  const [breadCrumb, setBreadCrumb] = useState([{ id: 0, name: "Video Library" }]);
  const [sortNameType, setSortNameType] = useState({});
  const [sortBy, setSortBy] = useState(''); // Initialize the sorting order to 'asc'
  var sortName = { name: '', sortValue: '', sortBy: '' }
  const searchList = useSelector((state) => (state.search));
  const formatData = transformationHelper().getTransformationFunction;
  const [uploadMode, setUploadMode] = useState('video')
  console.log('a3',uploadMode)
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.target.getBoundingClientRect();
        setWidthHeader((parseInt(width) - 10) + 'px');
      }
    });
    observer.observe(headerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  const handleGridViewClick = () => {
    setShowGridView(true);
    setShowTreeView(false);
  };
  const openDrawerPublish = (id) => {
    setEditFlag({ flag: false, value: "edit" })
    dispatch(openDrawer(id));
  }

  const handleTreeViewClick = () => {
    setShowGridView(false);
    setShowTreeView(true);
  };
  const handleButtonClick = () => {
    setShowCode(!showCode);
  };
  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
  }
  useEffect(() => {
    if (categoryListData.categoryList.length == 0) {
      ApiService.get('category').then(res => {
        let response = res.data.data
        if (response) {
          // setCategoryList(response)
          dispatch(setCategoryDataList(response))
        }
      })
    }
  }, [categoryListData])

  useEffect(function () {
    if (userList?.userId) {
      apiCallingDashboard()
    }
  }, [userList]);

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  function apiCallingDashboard(pageNo = dashboardScroll.pageNo, scrollFlag = dashboardScroll.scrollFlag) {
    if (pageNo + 1 == 1) {
      setLoading(true)
    }
    let params = {
      limit: 30,
      page: pageNo + 1,
      orderBy: sortName.sortBy || 'asc',
      sorting: 'name',
      related: ['streamCategory','streamSubcategory','modifyBy','uploadedBy']
      // related: ['streamCategory','streamSubcategory']
      // related: 'streamSubcategory'
      // related: 'type,name',
      // userId: userList?.userId
  }

  if (sortName.sortValue && sortName.sortBy || sortNameType.sortValue && sortNameType.sortBy) {
      // dataParams.related = sortName.sortValue || sortNameType.sortValue;
      // dataParams.sortBy = sortName.sortBy || sortNameType.sortBy;
  }
  
  function fetchData() {
  ApiService.get('recommendedVideo', params)
    .then(res => {
      console.log('a2', params);
      let data = res.data;
      console.log('a2', data);
      let mergeFileFolder = [...data.video, ...data.audio];
      console.log('a2', mergeFileFolder);

      setLoading(false);
      setMetaData({ mergeFileFolder });
      setDashboardList(mergeFileFolder);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
      methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" });
    });
}

// Call fetchData initially to start the process
fetchData();

  }

  function formatedData(data) {
    const tempData = {
      // "id": (data?.pinned?.deleted_at != null) ? 'file_undefined' : (data?.pinned?.type == "directory" ? 'folder_' : 'file_') + data?.pinned?.id,
      "id": data?.id,
      ...data,
      "name": data?.name,
      "date": data?.updated_at,
      'created_at': data?.created_at,
      'videoLength': data?.videoPlaylist?.length,
      'audioLength': data?.audioPlaylist?.length,
      'thumbNailUrl': data?.thumbNailUrl,
      'videoUrl':data?.videoUrl,
      // "pinnedId": data?.pinned?.id
    }
    console.log('a2',formatedData(data))
    return tempData;
  }

  const sortByNameType = (value) => {
    sortName = {
      name: 'dashboard',
      sortValue: value,
      sortBy: sortBy == '' || sortBy == 'desc' ? 'asc' : 'desc'
    };
    setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
    setSortNameType()
    setSortNameType(sortName)
    apiCallingDashboard(0, false)
  }

  // ----------------------------- UPDATE META DATA ------------
  const updateMetaData = (count) => {
    var updatedMetaData = {...metaData, total: metaData.total + count};
    setMetaData(updatedMetaData);
  }


  // const [dashboardList, setDashboardList] = useState([]);
  const [dashboardListMaster, setDashboardListMaster] = useState([]);
  const [hardRefresh, setHardRefresh] = useState(true);
  // const [metaData, setMetaData] = useState();
  const addNewVideo = (video, isRecommanded) => {
    let tempDashboardList = [...dashboardList];
    const updatedVideo = {
        ...video,
        isUpdated: true, // Add the isUpdated property
      };
    tempDashboardList.unshift(updatedVideo);
    setDashboardList(tempDashboardList);
    setDashboardListMaster([updatedVideo,...dashboardListMaster])
    setMetaData({ ...metaData, total: metaData.total + 1 })
    console.log("Roar", {tempDashboardList});
    if(isRecommanded){
        setHardRefresh(false);
    }
    console.log('a3',{updatedVideo, isRecommanded})
}

const editNewVideo = (video, isRecommanded) => {
    let tempDashboardList = [...dashboardList];
    const index = tempDashboardList.findIndex(item => item.id === video.id);
    console.log("t1",index)
    if (index !== -1) { 
        const updatedVideo = {
            ...video,
            isUpdated: true 
        };
        tempDashboardList.splice(index, 1, updatedVideo); 
        setDashboardList(tempDashboardList);
        setDashboardListMaster([updatedVideo, ...dashboardListMaster]); 
        console.log("Roar", { tempDashboardList });
        if (isRecommanded) {
            setHardRefresh(false);
        }
        console.log('a3',{ updatedVideo, isRecommanded });
    } else {
        console.error("Video not found in dashboardList");
    }
}

  const methodsAndState = {
    sortByNameType,
    setSnackBar,
    snackBar,
    dashboardList,
    setDashboardList,
    setSelectedItems,
    addNewVideo,
    editNewVideo,
    selectedItems,
    // setEditFlag, 
    setFileCount,
    // editFlag,
    // tagsFlag,
    handleGridViewClick,
    handleTreeViewClick,
    showGridView,
    showTreeView,
    handleButtonClick,
    tagsFlag,
    // dashboardList,
    // setDashboardList,
    setSelectedItems,
    selectedItems,
    setSelectedId,
    selectedId,
    openDialogPublish,
    setSnackBar,
    snackBar,
    setBreadCrumb,
    breadCrumb,
    setEditFlag,
    editFlag, apiCallingDashboard,
    openDrawerPublish, checkboxGridHelper,
    isFixed, showCode, setShowCode, setMetaData, metaData,
    setIsFixed, setCheckedData, checkedData, setHeaderCheckStatus, headerCheckStatus, updateMetaData,
    page: PageName.RecommendedVideos,
    individualAPI,
    uploadMode, setUploadMode,
    // individualAPIRename: individualAPI == 'audioPlaylist' ? 'renameAudio' : 'renameVideo' ,
  };
  console.log('a21',{setCheckedData,checkedData,headerCheckStatus})

  const numItemsPresent = dashboardList.length;
  const headerTags = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box>
          <DashboardHeading title={PageName.RecommendedVideos}
            // numItems={metaData?.total}
          numItems={numItemsPresent}
          />
        </Box>
      </Box>
    )
  }
  const upperDiv = document.getElementById('sticky');
  // Calculate the height of the upper div
  const upperDivHeight = upperDiv?.clientHeight - 20;

  return (
    <MyContext.Provider value={methodsAndState}>
      <Box>
        <Box>
          <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
            {/* <Card sx={{ p: 2, mb: 2, minHeight: '83vh' }}> */}
            <Box id="sticky" sx={{ paddingBottom: selectedItems.size ? '8px !important' : '12px !important', width: widthHeader, height: "auto !important" }}>
              <Box sx={{ flexWrap: 'wrap', width: "100%" }}>
                <Stack sx={{ backgroundColor: '#ffffff' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} >
                      {/* {!isFixedTop ? (selectedItems.size === 0 && headerTags()) : selectedItems.size === 0 && headerTags()} */}
                      {headerTags()}
                      {/* <VideoLibraryTools /> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={4} sx={{ zIndex: '10000 !important' }}> */}
                    <Grid item xs={12} sm={4}>
                      <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}>
                        <DashboardTools />
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Box>
            <Box className="gridTabularView" sx={{ mt: upperDivHeight + "px" }}>
              {/* <Box className="gridTabularView" sx={{ mt: { xl: "3rem", lg: "3rem", md: "4rem", sm: "3rem", xs: selectedItems.size ? "5rem" : '5rem' } }}> */}
              {/* <Box className="gridTabularView" sx={{ mt: "2rem" }}> */}
              {loading ? (
                <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }}>
                  {[...Array(12)].map((_, key) => (
                    <Grid key={key} item sx={{ m: '2rem' }}>
                      <Skeleton variant="rounded" width={150} height={150} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <VideoLibraryGridandTree checkboxRequied={false} isPinned={false} page={PageName.RecommendedVideos} />
                </Grid>
              )}
            </Box>
          </Card>
          <SharedDrawer id="editFile">
            <UploadDrawer />
          </SharedDrawer>
          <SharedDialog id="deleteDialog">
            <DeleteDialog />
          </SharedDialog>
          <SharedDialog id="renameDialog">
            <RenameDialog />
          </SharedDialog>
          <SharedDialog id="moveDialog">
            <MoveDialog />
          </SharedDialog>
          <TransitionsSnackbar />
        </Box>
      </Box>
      <DownloadBar context={MyContext} />
    </MyContext.Provider>
  )
}

export default ViewRecommendedVideo