import React, { useState, useContext, useMemo, useEffect } from 'react';
import MyContext from './Mycontext';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, TextField, Autocomplete } from '@mui/material';

const DashboardFilter = ({ handleClose }) => {
    const methodsAndState = useContext(MyContext);
    const categoryListData = useSelector((state) => state.category);
    const transformedArray = useMemo(() => {
        const newArray = [];
        methodsAndState.filterCategory.forEach(item => {
            item.subCategory.forEach(subCatItem => {
                newArray.push({
                    id: subCatItem.id,
                    subDescription: subCatItem.name,
                    description: subCatItem.description,
                    sorting: subCatItem.sorting,
                    category: subCatItem.category?.map(cat => ({
                        id: cat.id,
                        description: cat.description,
                        name: cat.name,
                        sorting: cat.sorting,
                        selectType: cat.selectType,
                        created_at: cat.created_at,
                        updated_at: cat.updated_at,
                        pivot: {
                            sub_category_id: subCatItem.id,
                            category_id: cat.id
                        }
                    }))
                });
                console.log('a1', subCatItem.id);
            });
        });
        console.log(('a1',methodsAndState.filterCategory))
        return newArray;
    }, [methodsAndState.filterCategory]);


    const [filterOpen, setFilterOpen] = useState(true);

    const [autocompleteValues, setAutocompleteValues] = useState({});

    useEffect(() => {
        console.log("A1", autocompleteValues);
    }, [autocompleteValues]);

    const handleAutocompleteChange = (value, category) => {
        setAutocompleteValues(prevValues => ({
            ...prevValues,
            [category.id]: {d: category,value}
        }));
    };

    const handleSubmit = (e) => {
        console.log("jawab",Object.keys(autocompleteValues))
        let idsTags = [];
        Object.keys(autocompleteValues)?.map((key) => {
            idsTags.push(autocompleteValues[key].value.id)
            // idsTags = [...idsTags, autocompleteValues[key].value.id];
        })
        console.log("Jawab", {idsTags})
        methodsAndState.onChangeSelectCategory(idsTags);
        
    }


    const handleButtonClick = () => {
        handleSubmit();
        handleClose();
    };

    return (
        <Box>
            {filterOpen && (
                <Box sx={{    minWidth: { xs: 'none', md: '40rem' } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1, ml: 1 }}>
                        <Button color='primary' variant="text" onClick={handleClose}><CloseIcon /></Button>
                    </Box>
                    <Grid container spacing={2}>
                        {categoryListData.categoryList.length > 0 && categoryListData.categoryList.map((d, i) => (
                            <Grid key={i} item xs={12} sm={6} md={6} lg={6} >
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    placeholder={d.name}
                                    multiple={d.selectType === "1"}
                                    onChange={(event, value) => handleAutocompleteChange(value, d)}
                                    options={d.subCategory}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField {...params} label={d.name} placeholder={d.description} />
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            <Box sx={{ mt: 2 }}>
                <Button variant="contained" size='medium' onClick={handleButtonClick}>SUBMIT</Button>
            </Box>
        </Box>
    );
};

export default DashboardFilter;
