import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Card, Stack, Grid, ButtonGroup, Button, Typography, Menu, MenuItem, ListItemText, ListItemIcon, ToggleButton, ToggleButtonGroup, InputLabel, FormControl, Select, Chip, FormControlLabel, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { styled, alpha } from '@mui/material/styles';
import MyContext from './Mycontext';
import AppsIcon from '@mui/icons-material/Apps';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { userPermissions } from './Permission/required.permission';
import Can from './Permission/Can';
import { useSelector } from 'react-redux';
import VideoLibraryFilter from './DashboardFilter';
import gridview from '../../assets/images/KM-Manager/gridview.svg';
import tabularview from '../../assets/images/KM-Manager/tabularview.svg';

const StyledMenu = styled((props) => (
    <Menu
      sx={{zIndex:'99999 !important'}}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const UploadVideo = ({mode = 'video', changeMode = () => {}}) => {
    const methodsAndState = useContext(MyContext);
    const userPermissionsListRedux = useSelector((state) => (state.permission));
    const searchList = useSelector((state) => (state.search));
    const [userPermissionsList, setUserPermissionsList] = useState([]);

    const [alignment, setAlignment] = React.useState('left');
    const currentRoute = useSelector((state) => (state.routing.currentRoute));
    const uploadMode = methodsAndState.uploadMode ?? 'video';
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [alignmentValue, setAlignmentValue] = React.useState('left');

    const handleAlignmentValue = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    useEffect(() => {
        if (userPermissionsListRedux.permissionList) {
            setUserPermissionsList(userPermissionsListRedux.permissionList)
        }
    }, [userPermissionsListRedux])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const isMediumOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const buttonSX = {
        '&': {
            color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)", minWidth: "40px", padding: "5px 10px", '&:hover': {
                color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)"
            },
        },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#000000' }
    }

    const openDrawer = (mode) => {
      changeMode(mode);
      handleClose();
      methodsAndState.openDrawerPublish('createFile')
      console.log('a3',changeMode(mode))
    }

    return (
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          
            {!methodsAndState.tagsFlag ? "" :
                <Box>
                {/* <Can
                    userPermissions={userPermissionsList}
                    requirePermissions={[userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_CREATE, userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_CREATE]}
                    yes={ */}
                        {['/videos', '/audio'].indexOf(currentRoute) != -1 ? <Box>
                            {isMediumOrLarger ? (
                                <Button
                                    id='folderFileOption'
                                    variant="contained"
                                    size="medium"
                                    startIcon={<AddIcon />}
                                    aria-controls={methodsAndState.open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={methodsAndState.open ? 'true' : undefined}
                                    onClick={() => methodsAndState.openDrawerPublish('createFile')}
                                    disabled={searchList.searchValue != ""}>
                                     {uploadMode === 'video' ? 'UPLOAD VIDEO' : 'UPLOAD AUDIO'}
                                </Button>
                            ) : (
                                <Button
                                    id='folderFileOption'
                                    variant="contained"
                                    size="medium"
                                    aria-controls={methodsAndState.open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={methodsAndState.open ? 'true' : undefined}
                                    onClick={() => methodsAndState.openDrawerPublish('createFile')}
                                    >
                                    <AddIcon />
                                </Button>
                            )}
                        </Box> 
                        : null} 
                            {['/'].indexOf(currentRoute) != -1 ? <Box>
                            {isMediumOrLarger ? (
                                <Box>
                                <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                startIcon={<AddIcon />}
                              >
                                UPLOAD
                              </Button>
                              <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={() => {openDrawer('video');}} disableRipple>
                                  <VideoLibraryIcon />
                                 VIDEO
                                </MenuItem>
                                <MenuItem   onClick={() => {openDrawer('audio');}} disableRipple>
                                  <AudioFileIcon />
                                  AUDIO
                                </MenuItem>
                              </StyledMenu>
                              </Box>
                            ) : (
                                <Box>
                                <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                              >
                                <AddIcon />
                              </Button>
                              <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={() => methodsAndState.openDrawerPublish('createFile')} disableRipple>
                                  <VideoLibraryIcon />
                                 VIDEO
                                </MenuItem>
                                <MenuItem   onClick={() => methodsAndState.openDrawerPublish('createFile')} disableRipple>
                                  <AudioFileIcon />
                                  AUDIO
                                </MenuItem>
                              </StyledMenu>
                              </Box>
                            )}
                        </Box> 
                        : null} 
                        {/* /> */}
                    </Box>
            }
        </Box>
    );
};

export default UploadVideo;