// downloadBarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  percentage: 0,
};

const downloadBarSlice = createSlice({
  name: 'downloadBar',
  initialState,
  reducers: {
    openDownloadBar: (state, action) => {
      console.log("Inside Open");
      state.isOpen = true;
      // state.percentage = action.payload.percentage;
    },
    updateDownloadBar: (state, action) => {
      console.log("Inside Update", {percentage: action.payload.percentage, action});
      // state.isOpen = true;
      state.percentage = action.payload.percentage;
    },
    closeDownloadBar: (state) => {
      console.log("Inside Close");
      state.isOpen = false;
      state.percentage = 0;
    },
    stopDownload: (state) => {
      console.log("Inside Close");
      state.isOpen = false;
      state.percentage = 0;
    },
  },
});

export const { openDownloadBar, closeDownloadBar, updateDownloadBar,stopDownload } = downloadBarSlice.actions;
export default downloadBarSlice.reducer;
